import {
  Body,
  Verify,
  Email,
  calculateMultiple,
  AutoCheck,
  SignOff,
  FindLabelFromName,
  TableZero,
} from './FileDetailsHelpers';
import LoginState from '../LoginState';
import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import Toast from '../Toast';
import { PRICING_OPTIONS, STATUS_COLORS } from '../../helpers/constants';
import {
  todaysDateYYYY,
  autoFormatCurrency,
  unformatCurrency,
  getPropertyType,
  isAdmin,
} from '../../helpers/utils';
import ModalEmail from './ModalEmail';
import ModalEmailLog from './ModalEmailLog';
import ModalConfirm from '../ModalConfirm';
import ChecklistCard from './ChecklistCard';

export default class CtcChecklist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFile: this.props.id,
      fileData: [],
      ltvFico: [],
      lien: '0',
      zoningTest: false,
      incomeTest: false,
      errorMessage: '',
      successMessage: '',
      isLoading: true,
      checkList: {
        almostEmail: { checked: false },
        borrower: { checked: false },
        address: { checked: false },
        craQual: { checked: false },
        programType: { checked: false },
        ltv: { checked: false },
        dti: { checked: false },
        fico: { checked: false },
        loanPurpose: { checked: false },
        occupancyType: { checked: false },
        propertyType: { checked: false },
        propertyValue: { checked: false },
        hoa: { checked: false },
        pnlPrep: { checked: false },
        incomeType: { checked: false },
        income: { checked: false },
        reserves: { checked: false },
        asset: { checked: false },
        hazard: { checked: false },
        flood: { checked: false },
        title: { checked: false },
        purchaseContract: { checked: false },
        escrowInstructions: { checked: false },
        tax: { checked: false },
        appraisal: { checked: false },
        aus: { checked: false },
        liabilities: { checked: false },
        vesting: { checked: false },
        changeCtc: { checked: false },
        ctcEmail: { checked: false },
        complete: { checked: false },
      },
      showEmailTemplate: false,
      emailTemplate: [],
      almostTemplate: [],
      ctcTemplate: [],
      almostEmailLog: [],
      ctcEmailLog: [],
    };
    this.getCtcData();
  }

  async getCtcData() {
    var response = axios.get('/getloansummary', {
      params: { pFileID: this.state.activeFile },
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;

    var intPropType = parseInt(resultData.propertyType);
    if (parseInt(resultData.numberOfUnits) > 1) {
      resultData.propertyType = resultData.numberOfUnits + ' Unit';
    } else {
      resultData.propertyType = getPropertyType(intPropType);
    }

    switch (resultData.loanPurpose) {
      case 'PUR':
        resultData.loanPurpose = 'Purchase';
        break;
      case 'R/T':
        resultData.loanPurpose = 'R/T Refinance';
        break;
      default:
        resultData.loanPurpose = 'C/O Refinance';
        break;
    }

    try {
      resultData.occupancyType =
        PRICING_OPTIONS.occupancyType[
          parseInt(resultData.occupancyType) - 1
        ].label;
    } catch {
      resultData.occupancyType = '? OCCUPANCY TYPE ?';
    }

    response = axios.get('/getmaxltvminfico', {
      params: {
        pProgramCode: resultData.programCode,
        pUnitType: resultData.propertyType,
        pOccupancyType: resultData.occupancyType,
        pLoanPurpose: resultData.loanPurpose,
        pForeignNational: resultData.foreignNational === '1',
        pLoanAmount: resultData.firstLoan,
        pLTV: resultData.ltvCltvHcltv.split('%')[0],
        pFico: resultData.creditScore,
        pClosedDate: resultData.closedDate,
        pIncomeVerification: resultData.incomeVerification
      },
    });
    var maxLtvMinFico = (await response).data;

    response = axios.get('/getctcfields', {
      params: {
        pFileID: this.state.activeFile,
      },
    });

    var ctcFields = (await response).data;
    var checklistData = this.state.checkList;
    var statelist = {
      fileData: resultData,
      trustType: ctcFields.closingTrustType,
      selfEmployed: ctcFields.selfEmployed !== '0',
      craQualified: ctcFields.craQualified === '1',
      zoningTest: ctcFields.zoningTest === '1',
      incomeTest: ctcFields.incomeTest === '1',
      creditReports: ctcFields.creditReports,
      hazInsCompany: ctcFields.hazardInsurance || '',
      originalHazInsCompany: ctcFields.hazardInsurance || '',
      hazInsStart: ctcFields.hazardInsuranceStart || '',
      originalHazInsStart: ctcFields.hazardInsuranceStart || '',
      hazInsEnd: ctcFields.hazardInsuranceEnd || '',
      originalHazInsEnd: ctcFields.hazardInsuranceEnd || '',
      fldZone: ctcFields.floodInsurance === '1' || false,
      fldInsAmount: ctcFields.floodInsuranceAmount || '',
      originalFldInsAmount: ctcFields.floodInsuranceAmount || '',
      titleStartDate: ctcFields.titleStartDate || '',
      originalTitleStartDate: this.databaseDateFormat(ctcFields.titleStartDate),
      lien: ctcFields.lien || '0',
      appraisalValue: ctcFields.appraisalValue || '',
      originalAppraisalValue: ctcFields.appraisalValue || '',
      vesting: ctcFields.vesting === '1' || false,
      pnlPreparer: ctcFields.pnlPreparer,
      incomeVerify: ctcFields.incomeVerify,

      prelimItem: ctcFields.prelimItemNo || '',
      originalPrelimItem: ctcFields.prelimItemNo || '',
      prelimDocID: ctcFields.prelimReport || '',
      attDet: ctcFields.attDet === '1' || false,
      insExp: ctcFields.insExp === '1' || false,
      monthlyHoa: ctcFields.monthlyHoa || '',
      originalMonthlyHoa: ctcFields.monthlyHoa || '',
      monthlyTax: ctcFields.monthlyTax || '',
      originalMonthlyTax: ctcFields.monthlyTax || '',

      projectName: ctcFields.hoaName || '',
      originalProjectName: ctcFields.hoaName || '',
      dtiMatched: ctcFields.dtiMatched || '',
      originalDtiMatched: ctcFields.dtiMatched || '',
      rentalIncomeUsed: ctcFields.rentalIncomeUsed === '1',
      waiver: ctcFields.waiver === '1',

      liabilitiesTbpo: ctcFields.liabilitiesTbpo || '',
      liabilitiesNc: ctcFields.liabilitiesNc || '',

      escrowToFunding: ctcFields.escrowToFunding === '1',
      appraisalToFunding: ctcFields.appraisalToFunding === '1',
      piw: ctcFields.piw === '1',
      ho6w: ctcFields.ho6w === '1',

      almostTemplate: ctcFields.almostTemplate,
      almostEmailLog: ctcFields.almostEmailLog,
      ctcTemplate: ctcFields.ctcTemplate,
      ctcEmailLog: ctcFields.ctcEmailLog,

      cashToClose: ctcFields.cashToClose || '$0.00',
      verifiedAssets: ctcFields.verifiedAssets || '$0.00',
      ltvFico: maxLtvMinFico,
      isLoading: false,
    };

    if (ctcFields.creditReports) {
      for (var j = 0; j < ctcFields.creditReports.length; j++) {
        statelist['crDate' + j] = ctcFields.creditReports[j].pullDate;
        statelist['originalCrDate' + j] = ctcFields.creditReports[j].pullDate;
        checklistData['cr' + ctcFields.creditReports[j].docID] = {
          checked: false,
        };
      }
    }

    // get checklist data
    response = axios.get('/getchecklistdata', {
      params: { pFileID: this.state.activeFile, pChecklistType: 'CTC' },
    });

    var checkData = (await response).data;

    for (var k = 0; k < checkData.length; k++) {
      checklistData[checkData[k].fieldName] = {
        checked: checkData[k].isChecked === 'True',
        timestamp: checkData[k].timestamp,
        userName: checkData[k].userName,
      };
    }
    statelist['checkList'] = checklistData;
    this.setState(statelist);
  }

  // fieldsToUpdate [tableName, fieldName, dataValue, name, checkboxName, conditions]
  async updateField(fieldsToUpdate) {
    for (var i = 0; i < fieldsToUpdate.length; i++) {
      var response = axios.get('/updatefield', {
        params: {
          pFileID: this.state.activeFile,
          pTableName: fieldsToUpdate[i][0], // tableName
          pFieldName: fieldsToUpdate[i][1], // fieldName
          pValue: fieldsToUpdate[i][2], // dataValue
          pConditions: fieldsToUpdate[i][5], // conditions
        },
      });
      var resultData = (await response).data;
      if (resultData === 'Success') {
        this.setState({
          ['original' +
          fieldsToUpdate[i][3].substring(0, 1).toUpperCase() +
          fieldsToUpdate[i][3].substring(1)]: fieldsToUpdate[i][2],
          [fieldsToUpdate[i][3] + 'Updated']: true,
        });
        // if we cleared / empty string
        if (!fieldsToUpdate[i][2]) {
          var checkList = this.state.checkList;
          checkList[fieldsToUpdate[i][4]].checked = false;
          this.setState({ checkList: checkList });
          this.setChecklistField(fieldsToUpdate[i][4], false);
        }
      } else {
        this.setState({ errorMessage: 'Error: Database Not Updated!' });
      }
    }
  }

  // fieldsToUpdate [fieldName, docID, dataValue, checkboxName, stateField]
  async updateDynamicDataField(fieldsToUpdate) {
    for (var i = 0; i < fieldsToUpdate.length; i++) {
      var response = axios.get('/updatedynamicfield', {
        params: {
          pFileID: this.state.activeFile,
          pList: 'CTC',
          pFieldName: fieldsToUpdate[i][0], // fieldName
          pDocID: fieldsToUpdate[i][1], // docID
          pDataValue: fieldsToUpdate[i][2].toString().trim(), // dataValue
        },
      });
      var resultData = (await response).data;
      if (resultData === 'Success') {
        if (fieldsToUpdate[i][4]) {
          this.setState({
            ['original' +
            fieldsToUpdate[i][4].substring(0, 1).toUpperCase() +
            fieldsToUpdate[i][4].substring(1)]: this.state[fieldsToUpdate[i][4]]
              .toString()
              .trim(),
            [fieldsToUpdate[i][4] + 'Updated']: true,
          });
        }
        // if we cleared / empty string
        if (!fieldsToUpdate[i][2] && fieldsToUpdate[i][3]) {
          var checkList = this.state.checkList;
          checkList[fieldsToUpdate[i][3]].checked = false;
          this.setState({ checkList: checkList });
          this.setChecklistField(fieldsToUpdate[i][3], false);
        }
      } else {
        this.setState({ errorMessage: 'Error: Database Not Updated!' });
      }
    }
  }

  setFieldFalse(fieldName) {
    const { checkList } = this.state;
    if (checkList[fieldName] && checkList[fieldName].checked === true) {
      checkList[fieldName].checked = false;
      this.setChecklistField(fieldName, false);
    }
  }

  async setChecklistField(fieldName, checked) {
    axios.get('/setchecklistfield', {
      params: {
        pFileID: this.state.activeFile,
        pFieldName: 'CTC-' + fieldName,
        pIsChecked: checked,
        pUserName: LoginState.userName,
      },
    });

    const { checkList } = this.state;
    if (checkList[fieldName].checked !== checked) {
      const fieldItem = checkList[fieldName];
      fieldItem.checked = checked;
    }
  }

  captureInput = e => {
    var target = e.target;
    var newValue = e.target.value;
    var position = e.target.selectionStart;

    if (
      ['monthlyTax', 'appraisalValue', 'monthlyHoa', 'fldInsAmount'].indexOf(
        e.target.name
      ) > -1
    ) {
      newValue = autoFormatCurrency(e.target.value);
      position = newValue.length - e.target.value.length + position;
    }
    this.setState(
      { [e.target.name]: newValue, [e.target.name + 'Updated']: false },
      () => {
        if (target.selectionStart) target.selectionStart = position;
        if (target.selectionEnd) target.selectionEnd = position;
      }
    );
  };

  _toggleCheckbox = ({ target: { name, checked } }) => {
    if (checked) {
      this.setState({ confirmTitle: name, showConfirmModal: true });
    } else if (isAdmin()) {
      this.setState({ confirmTitle: name, showClearModal: true });
    }
  };

  setCheckbox(checked) {
    const name = this.state.confirmTitle;
    const { checkList } = this.state;
    const fieldItem = checkList[name];
    fieldItem.checked = checked;
    fieldItem.timestamp = new Date()
      .toLocaleString()
      .split(/\D/)
      .slice(0, 3)
      .map(num => num.padStart(2, '0'))
      .join('/');
    fieldItem.userName = LoginState.userName;
    this.setState({
      checkList: checkList,
      showConfirmModal: false,
      showClearModal: false,
    });
    this.setChecklistField(name, checked);
    if (!checked) {
      checkList.complete.checked = false;
      this.setChecklistField('complete', false);
    }
  }

  pnlPreparerStr() {
    switch(this.state.pnlPreparer) {
      case "0":
        return "CPA Prepared";
      case "1":
        return "Borrower Prepared";
      default:
        return "---";
    }
  }

  incomeTypeStr() {
    switch(this.state.incomeVerify) {
      case "0":
        return "P&L Statement";
      case "1":
        return "Bank Statements";
      case "2":
        return "1099 Form";
      default:
        return "---";
    }
  }

  async downloadLoanFile(docID) {
    if (!docID) return;
    this.setState({ successMessage: 'Downloading File\nPlease Wait!' });
    const response = axios.get('/DownloadLoanFile', {
      params: {
        pFileID: this.state.activeFile,
        pDocumentID: docID,
      },
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;

    // if we couldn't get a file back, abort
    if (!resultData) {
      return;
    }

    var binary = atob(resultData.documentData);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    var file = new Blob([new Uint8Array(array)]);

    // this code below will download the file we selected
    const url = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = url;
    a.download = resultData.documentName + '.' + resultData.documentExension;
    a.click();
    URL.revokeObjectURL(url);

    this.setState({ successMessage: '' });
  }

  async setLoanStatus(status) {
    this.setState({ changingState: status });
    var response = axios.get('/updateLoanStatus', {
      params: {
        pFileID: this.state.activeFile,
        pLoanStatus: status,
        pLoanPurpose: this.state.fileData.loanPurpose,
      },
    });
    var resultData = (await response).data;
    if (resultData === 'Success') {
      var file = this.state.fileData;
      file.loanStatus = status;
      switch (status) {
        case 'Clear to Close':
          file.ctcDate = todaysDateYYYY();
          break;
        default:
          break;
      }
      this.setState({ fileData: file, changingState: false });
    } else {
      this.setState({ errorMessage: resultData, changingState: false });
    }
  }
  
  delay(milliseconds) {
    return new Promise(resolve => {
      setTimeout(resolve, milliseconds);
    });
  }

  async allChecked() {
    var visibleCount = 0;
    var checkedCount = 0;
    const { checkList, fileData, creditReports } = this.state;
    for (var prop in checkList) {
      if (prop === 'complete') continue;
      // skip entries that do not apply
      if (prop === 'craQual' && !this.state.fileData.programCode.endsWith('C'))
        continue;
      if (prop === 'vesting' && this.state.trustType !== "2")
        continue;
      if (
        prop === 'hoa' &&
        !(
          fileData.propertyType.includes('PUD') ||
          fileData.propertyType.includes('Condo')
        )
      )
        continue;
      if (
        prop === 'reserves' &&
        !(
          fileData.programCode.includes('MTGP') ||
          fileData.occupancyType === 'Second Home' ||
          fileData.occupancyType === 'Investment' ||
          (fileData.programCode.includes('DU') &&
            fileData.loanPurpose.includes('C/O') &&
            fileData.dti && fileData.dti.includes('/') &&
            parseFloat(fileData.dti.split('/')[1].replace('%', '')) > 45)
        )
      )
        continue;
      if (prop === 'flood' && !this.state.fldZone) continue;
      if (
        prop === 'purchaseContract' &&
        !fileData.loanPurpose.includes('Purchase')
      )
        continue;
      if (
        prop === 'aus' &&
        !(
          fileData.programCode.includes('DU') ||
          fileData.programCode.includes('LP')
        )
      )
        continue;
      if (prop === 'escrowInstructions' && this.state.escrowToFunding) continue;
      if (prop === 'appraisal' && this.state.appraisalToFunding) continue;
      if (prop === 'propertyValue' && this.state.piw) continue;
      if (prop === 'hazard' && this.state.ho6w) continue;
      if (prop === 'pnlPrep' && (!this.state.fileData.programCode.includes('MTGP1') || !this.state.selfEmployed)) continue;
      if (prop === 'incomeType' && (!this.state.fileData.programCode.includes('MTGP2') || !this.state.selfEmployed)) continue;
      if (prop === 'income' && (this.state.fileData.programCode.includes('MTGP2') && this.state.selfEmployed)) continue;

      visibleCount++;
      if (prop === 'changeCtc') {
        if (this.state.fileData.ctcDate !== '---') {
          checkedCount++;
        }
        continue;
      }
      if (prop === 'ltv') {
        if (
          parseFloat(this.state.ltvFico.maxLtv) >=
          parseFloat(fileData.ltvCltvHcltv.split('%')[0])
        )
          checkedCount++;
        continue;
      }
      if (
        Object.prototype.hasOwnProperty.call(checkList, prop) &&
        checkList[prop].checked
      )
        checkedCount++;
    }

    var completePercent = this.state.fileData.completePercent
      ?.split('CTC')[1]
      .split('(')[1]
      .split(')')[0];
    if (
      completePercent.toString() !==
      Math.round((checkedCount / visibleCount) * 100).toString()
    ) {
      this.state.fileData.completePercent =
        this.state.fileData.completePercent?.split('CTC')[0] +
        'CTC (' +
        Math.round((checkedCount / visibleCount) * 100).toString() +
        ')' +
        this.state.fileData.completePercent
          ?.split('CTC')[1]
          .substring(
            this.state.fileData.completePercent?.split('CTC')[1].indexOf(')') +
              1
          );
      this.props.updatePercent(
        'CTC',
        Math.round((checkedCount / visibleCount) * 100).toString()
      );
    }
    if (checkedCount < visibleCount) {
      if(this.state.checkList.complete.checked == true) {
        this.state.checkList.complete.checked = false;
        this.setChecklistField('complete', false);
        await this.delay(500);
        this.props.updatePercent('CTC', Math.round((checkedCount / visibleCount) * 100).toString());
      }
      return false;
    }
    return true;
  }

  markComplete() {
    axios.get('/CtcChecklistComplete', {
      params: { pFileID: this.state.activeFile },
    });
    this.setChecklistField('complete', true);
    var cl = this.state.checkList;
    cl.complete.checked = true;
    this.setState({ checkList: cl });

    this.props.updatePercent('CTC', 'complete');
  }

  checkAgainstClosingDate(reportType, dateValue) {
    var daysToAdd =
      !this.state.fileData.programCode.includes('MTGP') &&
      reportType === 'Credit'
        ? 120
        : 90;

    // if null or empty, skip
    if (!dateValue || !this.state.fileData.signingDate) {
      return;
    }

    var expDate = moment(dateValue, 'YYYY-MM-DD').add(daysToAdd, 'day');
    var funding = moment(this.state.fileData.signingDate, 'MM/DD/YYYY');
    if (expDate.isBefore(funding))
      return (
        'Report Will Expire (' +
        expDate.format('MM/DD/YY') +
        ')\nBefore Closing Date (' +
        funding.format('MM/DD/YY') +
        ')'
      );
    else return null;
  }

  checkAgainstFundingDate(dateValue) {
    // if null or empty, skip
    if (!dateValue || !this.state.fileData.fundingDate) {
      return;
    }

    var stDate = moment(dateValue, 'YYYY-MM-DD');
    var funding = moment(this.state.fileData.fundingDate, 'MM/DD/YYYY');
    if (stDate.isAfter(funding))
      return (
        'Insurance Must Start (' +
        stDate.format('MM/DD/YY') +
        ')\nOn Or Before Funding Date (' +
        funding.format('MM/DD/YY') +
        ')'
      );
    else return null;
  }

  getAnnualExpirationDate(dateValue) {
    try {
      var expDate = moment(dateValue, 'YYYY-MM-DD').add(1, 'year');

      // check for expiration
      var extra = '';
      if (expDate < moment().add(-1, 'day')) extra = ' (Expired!)';

      return expDate.format('MM/DD/YY') + extra;
    } catch {
      return '';
    }
  }

  getExpirationDate(reportType, dateValue) {
    try {
      var daysToAdd =
        !this.state.fileData.programCode.includes('MTGP') &&
        (reportType === 'Credit' || reportType === 'Title')
          ? 120
          : 90;
      if (!dateValue || !this.state.fileData.signingDate) {
        return;
      }
      var expDate = moment(dateValue, 'YYYY-MM-DD').add(daysToAdd, 'day');

      // check for expiration or almost expired
      var today14 = moment().add(14, 'day');
      var extra = '';
      if (expDate < moment().add(-1, 'day')) extra = ' (Expired!)';
      else if (expDate < today14) extra = ' (Expiring Soon!)';

      return expDate.format('MM/DD/YY') + extra;
    } catch {
      return '';
    }
  }

  databaseDateFormat(dateValue) {
    try {
      var curDate = moment(dateValue, 'YYYY-MM-DD');
      return curDate.format('MM/DD/YYYY');
    } catch {
      return '';
    }
  }

  dtiUnderLimits() {
    const { ltvFico } = this.state;
    try {
      var split = this.state.fileData.dti.split('/');
      var front = parseFloat(split[0].trim().replace('%', ''));
      var back = parseFloat(split[1].trim().replace('%', ''));
      return front <= ltvFico.maxFrontDTI && back <= ltvFico.maxBackDTI;
    } catch {
      return false;
    }
  }

  async ClearAll() {
    await axios.get('/ClearCheckboxes', {
      params: { pFileID: this.state.activeFile, checklist: 'CTC' },
    });
    this.setState({ showClearAllModal: false });
    this.getCtcData();
  }

  render() {
    const { isLoading, fileData, checkList, ltvFico } = this.state;
    if (isLoading) {
      return (
        <React.Fragment>
          <h1 className="h5">CTC Checklist</h1>
          <Body>
            <div style={{ width: '100%', textAlign: 'center' }}>
              <img
                src="../images/spinner.gif"
                alt=""
                height="64px"
                width="64px"
              />
            </div>
          </Body>
        </React.Fragment>
      );
    }

    const hazInsCheck = this.checkAgainstFundingDate(this.state.hazInsStart);
    const hazInsExp = this.getAnnualExpirationDate(this.state.hazInsStart);
    const fldInsCheck = this.checkAgainstFundingDate(this.state.fldInsStart);
    const titleExpDate = this.getExpirationDate(
      'Title',
      this.state.titleStartDate
    );

    return (
      <React.Fragment>
        <h1 className="h5">
          CTC Checklist
          {isAdmin() && (
            <button className="btn btn-outline-secondary"
              style={{
                fontSize: '12px',
                height: '24px',
                padding: '2px',
                width: '70px',
                marginLeft: '15px',
                pointerEvents: 'auto',
                position: 'relative',
                top: '-2px'
              }}
              onClick={() => this.setState({showClearAllModal: true}) }
            > 
              Clear All
            </button>
          )}
        </h1>
        <div>
          <h6>
            {fileData.programCode} + {fileData.loanPurpose} +{' '}
            {fileData.occupancyType} + {fileData.propertyType}
          </h6>
          <p className="text-secondary">
            (Please Check All Information Below For Spelling and Accuracy)
          </p>

          {/* Almost Email */}
          <ChecklistCard
            checked={checkList.almostEmail.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="almostEmail"
                  name="almostEmail"
                  checked={checkList.almostEmail.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="almostEmail">
                  Send Email
                </label>
              </div>
            }
            body={
              <div>
                <Email />
                Almost CTC
                <button
                  className="btn btn-sm btn-outline-primary ms-2"
                  onClick={() => {
                    this.setState({
                      showEmailTemplate: true,
                      emailTemplate: this.state.almostTemplate,
                      emailLog: this.state.almostEmailLog,
                    });
                  }}
                >
                  Preview
                </button>
                {this.state.almostEmailLog.length > 0 && (
                  <button
                    className="btn"
                    type="button"
                    style={{
                      height: '24px',
                      maxWidth: '24px',
                      marginLeft: '10px',
                      pointerEvents: 'auto',
                    }}
                    onClick={() =>
                      this.setState({
                        showEmailLog: true,
                        emailLog: this.state.almostEmailLog,
                      })
                    }
                  >
                    <i
                      className="bi bi-envelope-check"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        position: 'relative',
                        top: '-10px',
                        left: '-10px',
                      }}
                    />
                  </button>
                )}
              </div>
            }
            alert={
              checkList.almostEmail.checked && (
                <SignOff
                  user={checkList.almostEmail.userName}
                  date={checkList.almostEmail.timestamp}
                />
              )
            }
          />

          {/* Borrower */}
          <ChecklistCard
            checked={checkList.borrower.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="borrower"
                  id="borrower"
                  checked={checkList.borrower.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="borrower">
                  Borrower(s)
                </label>
              </div>
            }
            body={
              <div>
                <Verify tip="Spelling Check" />
                {fileData.allBorrowers}
              </div>
            }
            alert={
              checkList.borrower.checked && (
                <SignOff
                  user={checkList.borrower.userName}
                  date={checkList.borrower.timestamp}
                />
              )
            }
          />

          {/* Address */}
          <ChecklistCard
            checked={checkList.address.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="address"
                  id="address"
                  checked={checkList.address.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="address">
                  Address
                </label>
              </div>
            }
            body={
              <div>
                <TableZero>
                  <tbody>
                    <tr>
                      <td>
                        <Verify tip="Spelling Check and Google Search" />
                      </td>
                      <td>
                        <a
                          target="_blank"
                          href={
                            'https://www.google.com/search?q=' +
                            (
                              fileData.street.replaceAll(' ', '+') +
                              '+' +
                              fileData.city.replaceAll(' ', '+') +
                              ',+' +
                              fileData.state.replaceAll(' ', '+') +
                              '+' +
                              fileData.zip
                            ).replaceAll('&', '%26')
                          }
                          rel="noopener noreferrer"
                        >
                          {fileData.street} <br />
                          {fileData.city}, {fileData.state} {fileData.zip}
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </TableZero>
              </div>
            }
            alert={
              checkList.address.checked && (
                <SignOff
                  user={checkList.address.userName}
                  date={checkList.address.timestamp}
                />
              )
            }
          />

          {/* CRA Qualification */}
          {fileData.programCode.endsWith('C') && (
            <ChecklistCard
              checked={checkList.craQual.checked}
              label={
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="craQual"
                    id="craQual"
                    disabled={
                      this.state.craQualified &&
                      !this.state.zoningTest &&
                      !this.state.incomeTest
                    }
                    checked={checkList.craQual.checked}
                    onChange={this._toggleCheckbox}
                  />
                  <label className="form-check-label" htmlFor="craQual">
                    CRA Qualification
                  </label>
                </div>
              }
              body={
                <div>
                  <div className="d-flex">
                    <Verify tip="Check if Qualified" />
                    <div
                      className="form-check form-check-inline"
                      style={{ marginLeft: '3px' }}
                    >
                      <input
                        type="radio"
                        id="cra1"
                        name="cra"
                        className="form-check-input"
                        checked={this.state.craQualified}
                        onChange={() => void 0}
                        disabled={checkList.craQual.checked}
                        onClick={() => {
                          if (!this.state.craQualified) {
                            this.setState({ craQualified: true });
                            this.updateDynamicDataField([
                              ['CRA Qual', '', '1', '', ''],
                            ]);
                            checkList.craQual.checked = false;
                            this.setChecklistField('craQual', false);
                          }
                        }}
                      />
                      <label className="form-check-label" htmlFor="cra1">
                        Qualified
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        type="radio"
                        id="cra2"
                        name="cra"
                        className="form-check-input"
                        checked={!this.state.craQualified}
                        onChange={() => void 0}
                        disabled={checkList.craQual.checked}
                        onClick={() => {
                          if (this.state.craQualified) {
                            this.setState({ craQualified: false });
                            this.updateDynamicDataField([
                              ['CRA Qual', '', '0', '', ''],
                            ]);
                            checkList.craQual.checked = false;
                            this.setChecklistField('craQual', false);
                          }
                        }}
                      />
                      <label className="form-check-label" htmlFor="cra2">
                        Not Qualified
                      </label>
                    </div>
                  </div>
                  {this.state.craQualified && (
                    <TableZero>
                      <tbody>
                        <tr>
                          <td>
                            <Verify tip="Qualification Type" />
                          </td>
                          <td>
                            {/* Passed Zoning Test */}
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="zoningTest"
                              id="zoningTest"
                              checked={this.state.zoningTest}
                              onChange={() => void 0}
                              disabled={checkList.craQual.checked}
                              onClick={() => {
                                this.updateDynamicDataField([
                                  [
                                    'Zoning Test',
                                    '',
                                    this.state.zoningTest ? '0' : '1',
                                    '',
                                    '',
                                  ],
                                ]);
                                this.setState({
                                  zoningTest: this.state.zoningTest
                                    ? false
                                    : true,
                                });
                                checkList.craQual.checked = false;
                                this.setChecklistField('craQual', false);
                              }}
                            />
                            <label
                              className="form-check-label"
                              style={{ marginLeft: '8px' }}
                              htmlFor="zoningTest"
                            >
                              Passed Zoning Test (Moderate/Low)
                            </label>
                            <br />
                            {/* Passed Income Test */}
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="incomeTest"
                              id="incomeTest"
                              checked={this.state.incomeTest}
                              onChange={() => void 0}
                              disabled={checkList.craQual.checked}
                              onClick={() => {
                                this.updateDynamicDataField([
                                  [
                                    'Income Test',
                                    '',
                                    this.state.incomeTest ? '0' : '1',
                                    '',
                                    '',
                                  ],
                                ]);
                                this.setState({
                                  incomeTest: this.state.incomeTest
                                    ? false
                                    : true,
                                });
                                checkList.craQual.checked = false;
                                this.setChecklistField('craQual', false);
                              }}
                            />
                            <label
                              className="form-check-label"
                              style={{ marginLeft: '8px' }}
                              htmlFor="incomeTest"
                            >
                              Passed Income Test (Less Than 80%)
                            </label>
                          </td>
                        </tr>
                      </tbody>
                    </TableZero>
                  )}
                </div>
              }
              alert={
                checkList.craQual.checked && (
                  <SignOff
                    user={checkList.craQual.userName}
                    date={checkList.craQual.timestamp}
                  />
                )
              }
            />
          )}

          {/* Program Type */}
          <ChecklistCard
            checked={checkList.programType.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="programType"
                  id="programType"
                  checked={checkList.programType.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="programType">
                  Program
                </label>
              </div>
            }
            body={
              <div>
                <Verify />
                {fileData.programCode}
              </div>
            }
            alert={
              checkList.programType.checked && (
                <SignOff
                  user={checkList.programType.userName}
                  date={checkList.programType.timestamp}
                />
              )
            }
          />

          {/* LTV */}
          <ChecklistCard
            checked={
              parseFloat(ltvFico.maxLtv) >=
              parseFloat(fileData.ltvCltvHcltv.split('%')[0])
            }
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="ltv"
                  id="ltv"
                  checked={
                    parseFloat(ltvFico.maxLtv) >=
                    parseFloat(fileData.ltvCltvHcltv.split('%')[0])
                  } /* This checks ltv and not hcltv. change later */
                  onChange={() => void 0}
                />
                <label className="form-check-label" htmlFor="ltv">
                  LTV/CLTV/HCLTV
                </label>
              </div>
            }
            body={
              <div>
                {ltvFico.maxLtv === 0 ? (
                  <span>Scenario Not Supported <small>(Check Matrix)</small></span>
                ) : (
                  <span>
                    {fileData.ltvCltvHcltv}{' '}
                    {` <= Max LTV (` + ltvFico.maxLtv + `%)`}
                  </span>
                )}
              </div>
            }
            alert={
              <AutoCheck
                passed={
                  parseFloat(ltvFico.maxLtv) >=
                  parseFloat(fileData.ltvCltvHcltv.split('%')[0])
                }
              />
            }
          />

          {/* DTI */}
          {(checkList.dti.checked = this.dtiUnderLimits())}
          <ChecklistCard
            checked={checkList.dti.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="dti"
                  id="dti"
                  checked={checkList.dti.checked}
                  onChange={() => void 0}
                />
                <label className="form-check-label" htmlFor="dti">
                  DTI
                </label>
              </div>
            }
            body={
              <div>
                {fileData.dti}{' '}
                {' <= Max (' +
                  ltvFico.maxFrontDTI +
                  '.00% / ' +
                  ltvFico.maxBackDTI +
                  '.00%)'}
              </div>
            }
            alert={
              checkList.dti.checked && (
                <AutoCheck passed={checkList.dti.checked} />
              )
            }
          />

          {/* CREDIT SCORE - FICO */}
          {
            (checkList.fico.checked =
              parseFloat(ltvFico.minFico) <= parseFloat(fileData.creditScore))
          }
          <ChecklistCard
            checked={checkList.fico.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="fico"
                  id="fico"
                  checked={checkList.fico.checked}
                  onChange={() => void 0}
                />
                <label className="form-check-label" htmlFor="fico">
                  Credit Score
                </label>
              </div>
            }
            body={
              <div>
                {
                  (checkList.fico.checked =
                    parseFloat(ltvFico.minFico) <=
                    parseFloat(fileData.creditScore))
                }
                <p className="mb-0">
                  {fileData.creditScore === '---' ? (
                    <React.Fragment>No Credit Score Available</React.Fragment>
                  ) : (
                    <React.Fragment>
                      {ltvFico.minFico === 1000 ? (
                        <span>Scenario Not Supported <small>(Check Matrix)</small></span>
                      ) : (
                        <span>
                          {fileData.creditScore}{' '}
                          {` >= Min FICO (` + ltvFico.minFico + `)`}
                        </span>
                      )}
                    </React.Fragment>
                  )}
                </p>
              </div>
            }
            alert={
              <AutoCheck
                passed={
                  parseFloat(ltvFico.minFico) <=
                  parseFloat(fileData.creditScore)
                }
                tip={
                  fileData.creditScore === '---'
                    ? 'No Credit Score Found'
                    : 'Credit Score Under Minimum Required Limit'
                }
              />
            }
          />

          {/* Credit Report Expiration Date(s) */}
          {this.state.creditReports.map(creditReport => {
            const dateCheck = this.checkAgainstClosingDate(
              'Credit',
              this.state['crDate' + creditReport.index]
            );
            const expDate = this.getExpirationDate(
              'Credit',
              this.state['crDate' + creditReport.index]
            );
            return (
              <div key={creditReport.docID}>
                <ChecklistCard
                  checked={checkList['cr' + creditReport.docID].checked}
                  label={
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        name={'cr' + creditReport.docID}
                        id={'cr' + creditReport.docID}
                        checked={checkList['cr' + creditReport.docID].checked}
                        onChange={this._toggleCheckbox}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={'cr' + creditReport.docID}
                      >
                        Credit Report
                      </label>
                      <br />
                      <table width="100%">
                        <tbody>
                          <tr>
                            <td style={{ textAlign: 'center' }}>
                              <i
                                className="bi bi-download"
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                  this.downloadLoanFile(creditReport.docID)
                                }
                              ></i>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  }
                  body={
                    <div>
                      {expDate ? (
                        <div>
                          Exp Date:&nbsp;
                          <span
                            style={{
                              color: expDate.length > 9 ? 'red' : 'black',
                            }}
                          >
                            {expDate}
                          </span>
                        </div>
                      ) : (
                        <div>Start / Expiration Date Not Found</div>
                      )}
                    </div>
                  }
                  alert={
                    this.state['crDate' + creditReport.index] && (
                      <React.Fragment>
                        {checkList['cr' + creditReport.docID].checked && (
                          <SignOff
                            user={checkList['cr' + creditReport.docID].userName}
                            date={checkList['cr' + creditReport.docID].timestamp}
                          />
                        )}
                      </React.Fragment>
                    )
                  }
                />
              </div>
            );
          })}

          {/* Loan Purpose */}
          <ChecklistCard
            checked={checkList.loanPurpose.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="loanPurpose"
                  id="loanPurpose"
                  checked={checkList.loanPurpose.checked}
                  disabled={
                    fileData.programCode.endsWith('C') &&
                    fileData.loanPurpose.includes('C/O')
                  }
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="loanPurpose">
                  Loan Purpose
                </label>
              </div>
            }
            body={
              <div>
                <Verify />
                {fileData.loanPurpose}
              </div>
            }
            alert={
              fileData.programCode.endsWith('C') &&
              fileData.loanPurpose.includes('C/O') ? (
                <AutoCheck passed={false} tip="CRA Programs Do Not Allow C/O" />
              ) : (
                <React.Fragment>
                  {checkList.loanPurpose.checked && (
                    <SignOff
                      user={checkList.loanPurpose.userName}
                      date={checkList.loanPurpose.timestamp}
                    />
                  )}
                </React.Fragment>
              )
            }
          />

          {/* Occupancy Type */}
          <ChecklistCard
            checked={checkList.occupancyType.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="occupancyType"
                  id="occupancyType"
                  checked={checkList.occupancyType.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="occupancyType">
                  Occupancy Type
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <Verify />
                  {fileData.occupancyType}
                </div>
                {fileData.occupancyType.includes('Second') && (
                  <div>
                    <Verify tip="For Second Home" />
                    LOE Received
                  </div>
                )}
                {fileData.occupancyType.includes('Investment') && (
                  <React.Fragment>
                    <div>
                      <TableZero>
                        <tbody>
                          <tr>
                            <td style={{ verticalAlign: 'top' }}>
                              <Verify tip="For Investment Home" />
                            </td>
                            <td>
                              <div className="hstack">
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    id="rental1"
                                    name="rental"
                                    checked={this.state.rentalIncomeUsed}
                                    onChange={() => void 0}
                                    disabled={checkList.occupancyType.checked}
                                    onClick={() => {
                                      this.setState({ rentalIncomeUsed: true });
                                      this.updateDynamicDataField([
                                        ['Rental Income Used', '', '1', '', ''],
                                      ]);
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="rental1"
                                  >
                                    Rental Income Used
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    id="rental2"
                                    name="rental"
                                    checked={!this.state.rentalIncomeUsed}
                                    onChange={() => void 0}
                                    disabled={checkList.occupancyType.checked}
                                    onClick={() => {
                                      this.setState({
                                        rentalIncomeUsed: false,
                                      });
                                      this.updateDynamicDataField([
                                        ['Rental Income Used', '', '0', '', ''],
                                      ]);
                                    }}
                                  />
                                  <label
                                    htmlFor="rental2"
                                    className="form-check-label"
                                  >
                                    Not Used
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </TableZero>
                    </div>
                    {this.state.rentalIncomeUsed && (
                      <div>
                        <Verify tip="If Rental Income Used" />
                        Lease Agreement
                      </div>
                    )}
                  </React.Fragment>
                )}
              </div>
            }
            alert={
              checkList.occupancyType.checked && (
                <SignOff
                  user={checkList.occupancyType.userName}
                  date={checkList.occupancyType.timestamp}
                />
              )
            }
          />

          {/* Property Type */}
          <ChecklistCard
            checked={checkList.propertyType.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="propertyType"
                  id="propertyType"
                  checked={checkList.propertyType.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="propertyType">
                  Property Type
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <Verify /> 
                  {fileData.propertyType}
                </div>
                {(fileData.propertyType === 'PUD' ||
                  fileData.propertyType === 'Condo') && (
                  <div>
                    <Verify tip="For PUDs and Condos" />
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="att1"
                        name="attDet"
                        checked={this.state.attDet}
                        onChange={() => void 0}
                        disabled={checkList.propertyType.checked}
                        onClick={() => {
                          this.setState({ attDet: true });
                          this.updateDynamicDataField([
                            ['AttDet', '', '1', '', ''],
                          ]);
                        }}
                      />
                      <label htmlFor="att1" className="form-check-label">
                        Attached
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="att2"
                        name="attDet"
                        checked={!this.state.attDet}
                        onChange={() => void 0}
                        disabled={checkList.propertyType.checked}
                        onClick={() => {
                          this.setState({ attDet: false });
                          this.updateDynamicDataField([
                            ['AttDet', '', '0', '', ''],
                          ]);
                        }}
                      />
                      <label htmlFor="att2" className="form-check-label">
                        Detached
                      </label>
                    </div>
                  </div>
                )}
                {fileData.propertyType.includes('Unit') && (
                  <div className="hstack gap-3">
                    <Verify tip="For Multi-Unit Properties" />
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="rental3"
                        checked={this.state.rentalIncomeUsed}
                        disabled={checkList.propertyType.checked}
                        onChange={e => {
                          this.setState({ rentalIncomeUsed: e.target.checked });
                          this.updateDynamicDataField([
                            [
                              'Rental Income Used',
                              '',
                              e.target.checked ? '1' : '0',
                              '',
                              '',
                            ],
                          ]);
                        }}
                      />
                      <label className="form-check-label" htmlFor="rental3">
                        Rental Income Used
                      </label>
                    </div>
                  </div>
                )}
                {this.state.rentalIncomeUsed && (
                  <div>
                    <Verify tip="If Rental Income Used" />
                    Lease Agreement
                  </div>
                )}
              </div>
            }
            alert={
              checkList.propertyType.checked && (
                <SignOff
                  user={checkList.propertyType.userName}
                  date={checkList.propertyType.timestamp}
                />
              )
            }
          />

          {/* Property Value */}
          <ChecklistCard
            checked={checkList.propertyValue.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="propertyValue"
                  id="propertyValue"
                  disabled={this.state.piw}
                  checked={checkList.propertyValue.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="propertyValue">
                  Property Value
                </label>
              </div>
            }
            body={
              <div>
                <div className="vstack gap-3">
                  <div className="hstack">
                    <Verify />
                    <div>
                      <p className="mb-0">{fileData.propertyValue}</p>
                      <small>
                        (Please Check Value From Final Appraisal Report)
                      </small>
                    </div>
                  </div>
                  <div className="hstack gap-3">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="piw"
                        checked={this.state.piw}
                        disabled={checkList.propertyValue.checked}
                        onChange={e => {
                          this.updateDynamicDataField([
                            [
                              'PIW',
                              '',
                              e.target.checked ? '1' : '0',
                              '',
                              'piw',
                            ],
                          ]);
                          this.setState({ piw: e.target.checked });
                        }}
                      />
                      <label className="form-check-label" htmlFor="piw">
                        Property Inspection Waiver (PIW)
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            }
            alert={
              checkList.propertyValue.checked && (
                <SignOff
                  user={checkList.propertyValue.userName}
                  date={checkList.propertyValue.timestamp}
                />
              )
            }
          />

          {/* HOA */}
          {(this.state.originalMonthlyHoa !== this.state.monthlyHoa ||
            this.state.originalProjectName !== this.state.projectName) &&
            this.setFieldFalse('hoa')}
          {(fileData.propertyType.includes('PUD') ||
            fileData.propertyType.includes('Condo')) && (
            <ChecklistCard
              checked={checkList.hoa.checked}
              label={
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="hoa"
                    id="hoa"
                    checked={checkList.hoa.checked}
                    disabled={
                      !this.state.originalMonthlyHoa ||
                      !this.state.originalProjectName
                    }
                    onChange={this._toggleCheckbox}
                  />
                  <label className="form-check-label" htmlFor="hoa">
                    HOA
                  </label>
                </div>
              }
              body={
                <div className="vstack gap-3">
                  <div className="input-group">
                    <span className="input-group-text">Monthly:</span>
                    <input
                      type="text"
                      placeholder="$"
                      name="monthlyHoa"
                      className="form-control"
                      disabled={checkList.hoa.checked}
                      value={this.state.monthlyHoa || ''}
                      onChange={this.captureInput}
                    />
                    <button
                      className="btn btn-primary btn-sm"
                      disabled={
                        this.state.originalMonthlyHoa ===
                          this.state.monthlyHoa || checkList.hoa.checked
                      }
                      onClick={() =>
                        this.updateDynamicDataField([
                          [
                            'Monthly HOA',
                            '',
                            this.state.monthlyHoa,
                            '',
                            'monthlyHoa',
                          ],
                        ])
                      }
                    >
                      {this.state.monthlyHoaUpdated ? '✓' : 'Update'}
                    </button>
                  </div>
                  <div className="input-group">
                    <span className="input-group-text">Project Name:</span>
                    <input
                      type="text"
                      name="projectName"
                      value={this.state.projectName || ''}
                      className="form-control"
                      disabled={checkList.hoa.checked}
                      onChange={this.captureInput}
                    />
                    <button
                      className="btn btn-primary btn-sm"
                      disabled={
                        this.state.originalProjectName ===
                          this.state.projectName || checkList.hoa.checked
                      }
                      onClick={() =>
                        this.updateField([
                          [
                            'SubProp',
                            'ProjectName',
                            this.state.projectName,
                            'projectName',
                            '',
                          ],
                        ])
                      }
                    >
                      {this.state.projectNameUpdated ? '✓' : 'Update'}
                    </button>
                  </div>{' '}
                  <div>
                    <Verify
                      tip={
                        fileData.programCode.includes('MTGP')
                          ? ''
                          : 'QM: Can Bypass if Detached Property'
                      }
                    />
                    Master Insurance
                  </div>
                  {fileData.propertyType.includes('Condo') && (
                    <div>
                      <Verify
                        tip={
                          fileData.programCode.includes('MTGP')
                            ? 'Non-QM: Always Required'
                            : 'QM: Can Bypass if Detached Property'
                        }
                      />
                      HOA Questionnaire
                    </div>
                  )}
                </div>
              }
              alert={
                checkList.hoa.checked && (
                  <SignOff
                    user={checkList.hoa.userName}
                    date={checkList.hoa.timestamp}
                  />
                )
              }
            />
          )}

          {/* P&L Preparer */}
          {(fileData.programCode.includes('MTGP1') && this.state.selfEmployed) && (
          <ChecklistCard
            checked={checkList.pnlPrep.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="pnlPrep"
                  name="pnlPrep"
                  disabled={!this.state.pnlPreparer}
                  checked={checkList.pnlPrep.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="pnlPrep">
                  P&L Preparer
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <Verify
                    tip={
                      <span>
                        For MTGP1 Programs, If Self-Employed
                        <br />
                        We Store Information For Who Will Prepare 
                        <br />
                        The P&L Statement Used For Income Verification
                      </span>
                    }
                  />
                  {this.state.pnlPreparer ? 
                    this.pnlPreparerStr() : 
                    <span style={{color:'red'}}>Not Specified Yet (UW Checklist)</span>
                  }
                </div>
              </div>
            }
            alert={
              checkList.pnlPrep.checked && (
                <SignOff
                  user={checkList.pnlPrep.userName}
                  date={checkList.pnlPrep.timestamp}
                />
              )
            }
          />
          )}

          {/* Income Type */}
          {(fileData.programCode.includes('MTGP2') && this.state.selfEmployed) ? (
          <ChecklistCard
            checked={checkList.incomeType.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="incomeType"
                  name="incomeType"
                  disabled={!this.state.incomeVerify}
                  checked={checkList.incomeType.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="incomeType">
                  Income Type
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <Verify
                    tip={
                      <span>
                        For MTGP2 Programs, If Self-Employed
                        <br />
                        We Store Information For How The Income 
                        <br />
                        Will Be Verified From The UW Checklist
                      </span>
                    }
                  />
                  {this.state.incomeVerify ? 
                    this.incomeTypeStr() : 
                    <span style={{color:'red'}}>Not Specified Yet (UW Checklist)</span>
                  }
                </div>
              </div>
            }
            alert={
              checkList.incomeType.checked && (
                <SignOff
                  user={checkList.incomeType.userName}
                  date={checkList.incomeType.timestamp}
                />
              )
            }
          />
          ) : (
          <ChecklistCard
            checked={checkList.income.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="income"
                  id="income"
                  checked={checkList.income.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="income">
                  Income
                </label>
              </div>
            }
            body={
              <div>
                {!this.state.selfEmployed ? (
                  <div>
                    <Verify tip="Not Self-Employed" />
                    WVOE
                  </div>
                ) : (
                  <React.Fragment>
                    {fileData.programCode.includes('MTGP') ? (
                      <div>
                        <Verify tip="For Non-QM" />
                        P&L + LOE
                      </div>
                    ) : (
                      <div>
                        <Verify tip="For QM" />
                        Calculation on 1008
                      </div>
                    )}
                  </React.Fragment>
                )}
              </div>
            }
            alert={
              checkList.income.checked && (
                <SignOff
                  user={checkList.income.userName}
                  date={checkList.income.timestamp}
                />
              )
            }
          />
          )}

          {/* Reserves */}
          {(fileData.programCode.includes('MTGP') ||
            fileData.occupancyType === 'Second Home' ||
            fileData.occupancyType === 'Investment' ||
            (fileData.programCode.includes('DU') &&
              fileData.loanPurpose.includes('C/O') &&
              fileData.dti && fileData.dti.includes('/') &&
              parseFloat(fileData.dti.split('/')[1].replace('%', '')) > 45)) && (
            <ChecklistCard
              checked={checkList.reserves.checked}
              label={
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="reserves"
                    id="reserves"
                    checked={checkList.reserves.checked}
                    onChange={this._toggleCheckbox}
                  />
                  <label className="form-check-label" htmlFor="reserves">
                    Reserves
                  </label>
                </div>
              }
              body={
                <div>
                  <div>
                    <TableZero>
                      <tbody>
                        <tr>
                          <td>
                            <Verify />
                          </td>
                          <td>
                            {/* MTGP1 */}
                            {fileData.programCode.includes('MTGP1') &&
                              !fileData.programCode.endsWith('C') && (
                              <React.Fragment>
                                {fileData.foreignNational === '1' ? (
                                  <React.Fragment>
                                    <br />
                                    12 Months P/I
                                    <br />({fileData.pi} x 12 ={' '}
                                    <b>{calculateMultiple(fileData.pi, 12)}</b>)
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    {(parseInt(fileData.firstLoan.replaceAll(',', '').replaceAll('$', '')) <= 2000000 && 
                                      !fileData.loanPurpose.includes('C/O') /* && fileData.multiUnitADU !== '1' */
                                    ) ? (
                                      <React.Fragment>
                                        2 Months P/I
                                        <br />({fileData.pi} x 2 ={' '}
                                        <b>{calculateMultiple(fileData.pi, 2)}</b>
                                        )
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment>
                                        6 Months P/I
                                        <br />({fileData.pi} x 6 ={' '}
                                        <b>
                                          {calculateMultiple(fileData.pi, 6)}
                                        </b>
                                        )
                                      </React.Fragment>
                                    )}
                                  </React.Fragment>
                                )}
                              </React.Fragment>
                              )}

                            {/* MTGP1C */}
                            {fileData.programCode.includes('MTGP1') &&
                              fileData.programCode.endsWith('C') && (
                              <React.Fragment>
                                2 Months P/I
                                <br />({fileData.pi} x 2 ={' '}
                                <b>{calculateMultiple(fileData.pi, 2)}</b>
                                )
                              </React.Fragment>
                            )}

                            {/* MTGP2 */}
                            {fileData.programCode.includes('MTGP2') && (
                              <React.Fragment>
                                {/* Primary and Second Home */}
                                {fileData.occupancyType === 'Primary' ||
                                fileData.occupancyType === 'Second Home' ? (
                                  <React.Fragment>
                                    {/* LTV <= 75% */}
                                    {parseFloat(fileData.ltv.split('%')[0]) <=
                                    75 ? (
                                      <React.Fragment>
                                        {parseInt(
                                          fileData.firstLoan
                                            .replaceAll(',', '')
                                            .replaceAll('$', '')
                                        ) <= 1000000 ? (
                                          <React.Fragment>
                                            No Reserve Required
                                          </React.Fragment>
                                        ) : (
                                          <React.Fragment>
                                            3 Months P/I
                                            <br />({fileData.pi || '$---'} x 3 ={' '}
                                            <b>
                                              {calculateMultiple(
                                                fileData.pi,
                                                3
                                              )}
                                            </b>
                                            )
                                          </React.Fragment>
                                        )}
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment>
                                        {parseInt(
                                          fileData.firstLoan
                                            .replaceAll(',', '')
                                            .replaceAll('$', '')
                                        ) <= 1000000 ? (
                                          <React.Fragment>
                                            3 Months P/I
                                            <br />({fileData.pi || '$---'} x 3 ={' '}
                                            <b>
                                              {calculateMultiple(
                                                fileData.pi,
                                                3
                                              )}
                                            </b>
                                            )
                                          </React.Fragment>
                                        ) : (
                                          <React.Fragment>
                                            6 Months P/I
                                            <br />({fileData.pi || '$---'} x 6 ={' '}
                                            <b>
                                              {calculateMultiple(
                                                fileData.pi,
                                                6
                                              )}
                                            </b>
                                            )
                                          </React.Fragment>
                                        )}
                                      </React.Fragment>
                                    )}
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    {/* LTV <= 70% */}
                                    {parseFloat(fileData.ltv.split('%')[0]) <=
                                    70 ? (
                                      <React.Fragment>
                                        {parseInt(
                                          fileData.firstLoan
                                            .replaceAll(',', '')
                                            .replaceAll('$', '')
                                        ) <= 1000000 ? (
                                          <React.Fragment>
                                            No Reserve Required
                                          </React.Fragment>
                                        ) : (
                                          <React.Fragment>
                                            {parseInt(
                                              fileData.firstLoan
                                                .replaceAll(',', '')
                                                .replaceAll('$', '')
                                            ) <= 2000000 ? (
                                              <React.Fragment>
                                                3 Months P/I
                                                <br />({fileData.pi || '$---'} x
                                                3 ={' '}
                                                <b>
                                                  {calculateMultiple(
                                                    fileData.pi,
                                                    3
                                                  )}
                                                </b>
                                                )
                                              </React.Fragment>
                                            ) : (
                                              <React.Fragment>
                                                6 Months P/I
                                                <br />({fileData.pi || '$---'} x
                                                6 ={' '}
                                                <b>
                                                  {calculateMultiple(
                                                    fileData.pi,
                                                    6
                                                  )}
                                                </b>
                                                )
                                              </React.Fragment>
                                            )}
                                          </React.Fragment>
                                        )}
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment>
                                        {parseInt(
                                          fileData.firstLoan
                                            .replaceAll(',', '')
                                            .replaceAll('$', '')
                                        ) <= 1000000 ? (
                                          <React.Fragment>
                                            3 Months P/I
                                            <br />({fileData.pi || '$---'} x 3 ={' '}
                                            <b>
                                              {calculateMultiple(
                                                fileData.pi,
                                                3
                                              )}
                                            </b>
                                            )
                                          </React.Fragment>
                                        ) : (
                                          <React.Fragment>
                                            {parseInt(
                                              fileData.firstLoan
                                                .replaceAll(',', '')
                                                .replaceAll('$', '')
                                            ) <= 2000000 ? (
                                              <React.Fragment>
                                                6 Months P/I
                                                <br />({fileData.pi || '$---'} x
                                                6 ={' '}
                                                <b>
                                                  {calculateMultiple(
                                                    fileData.pi,
                                                    6
                                                  )}
                                                </b>
                                                )
                                              </React.Fragment>
                                            ) : (
                                              <React.Fragment>
                                                12 Months P/I
                                                <br />({fileData.pi || '$---'} x
                                                12 ={' '}
                                                <b>
                                                  {calculateMultiple(
                                                    fileData.pi,
                                                    12
                                                  )}
                                                </b>
                                                )
                                              </React.Fragment>
                                            )}
                                          </React.Fragment>
                                        )}
                                      </React.Fragment>
                                    )}
                                  </React.Fragment>
                                )}
                              </React.Fragment>
                            )}

                            {/* DU/LP - QM */}
                            {!fileData.programCode.includes('MTGP') && (
                              <React.Fragment>
                                {fileData.occupancyType === 'Primary' ? (
                                  <React.Fragment>
                                    {/* DU Program, C/O Refinance, DTI > 45% */}
                                    {fileData.programCode.includes('DU') &&
                                      fileData.loanPurpose.includes('C/O') &&
                                      fileData.dti && fileData.dti.includes('/') &&
                                      parseFloat(fileData.dti.split('/')[1].replace('%', '')) > 45 && (
                                        <React.Fragment>
                                          6 Months PITIA
                                          <br />({fileData.totalPiti ||
                                            '$---'}{' '}
                                          x 6 ={' '}
                                          <b>
                                            {calculateMultiple(
                                              fileData.totalPiti,
                                              6
                                            )}
                                          </b>
                                          )
                                        </React.Fragment>
                                      )}
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    {/* 2nd Home */}
                                    {fileData.occupancyType ===
                                      'Second Home' && (
                                      <React.Fragment>
                                        2 Months PITIA
                                        <br />({fileData.totalPiti || '$---'} x
                                        2 ={' '}
                                        <b>
                                          {calculateMultiple(
                                            fileData.totalPiti,
                                            2
                                          )}
                                        </b>
                                        )
                                      </React.Fragment>
                                    )}
                                    {/* Investment */}
                                    {fileData.occupancyType ===
                                      'Investment' && (
                                      <React.Fragment>
                                        6 Months PITIA + (Additional Based On #
                                        of REO's Owned)
                                        <br />({fileData.totalPiti || '$---'} x
                                        6 ={' '}
                                        <b>
                                          {calculateMultiple(
                                            fileData.totalPiti,
                                            6
                                          )}
                                        </b>
                                        )
                                      </React.Fragment>
                                    )}
                                  </React.Fragment>
                                )}
                              </React.Fragment>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </TableZero>
                  </div>
                </div>
              }
              alert={
                checkList.reserves.checked && (
                  <SignOff
                    user={checkList.reserves.userName}
                    date={checkList.reserves.timestamp}
                  />
                )
              }
            />
          )}

          {/* Asset */}
          <ChecklistCard
            checked={checkList.asset.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="asset"
                  id="asset"
                  checked={checkList.asset.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="asset">
                  Asset
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <TableZero>
                    <tbody>
                      <tr>
                        <td>
                          <Verify />
                        </td>
                        <td>
                          Cash to Close {`<`} Verified Assets
                          <br />({this.state.cashToClose} {`<`}{' '}
                          {this.state.verifiedAssets})
                        </td>
                      </tr>
                    </tbody>
                  </TableZero>
                </div>
                {fileData.loanPurpose === 'R/T Refinance' && (
                  <div>
                    <Verify tip="R/T Refinance" />
                    {`Cash to Close < $2,000`}
                  </div>
                )}
                {fileData.programCode.includes('MTGP') && (
                  <div>
                    <Verify tip="Non-QM" />
                    Worksheet
                  </div>
                )}
              </div>
            }
            alert={
              checkList.asset.checked && (
                <SignOff
                  user={checkList.asset.userName}
                  date={checkList.asset.timestamp}
                />
              )
            }
          />

          {/* Hazard Insurance */}
          {this.state.hazInsStart &&
            this.checkAgainstFundingDate(this.state.hazInsStart) &&
            checkList.hazard.checked &&
            (checkList.hazard.checked = false)}
          <ChecklistCard
            checked={checkList.hazard.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="hazard"
                  id="hazard"
                  disabled={
                    (!checkList.hazard.checked && !this.state.ho6w) &&
                    (!this.state.originalHazInsCompany ||
                    !this.state.originalHazInsStart ||
                    this.checkAgainstFundingDate(this.state.hazInsStart) ||
                    this.state.originalHazInsStart !== this.state.hazInsStart)
                  }
                  checked={checkList.hazard.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="hazard">
                  Hazard Insurance
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div className="input-group">
                  <span className="input-group-text">Company:</span>
                  <input
                    type="text"
                    name="hazInsCompany"
                    className="form-control"
                    disabled={checkList.hazard.checked}
                    value={this.state.hazInsCompany || ''}
                    onChange={this.captureInput}
                  />
                  <button
                    className="btn btn-primary btn-sm"
                    disabled={
                      this.state.originalHazInsCompany ===
                        this.state.hazInsCompany ||
                      checkList.hazard.checked
                    }
                    onClick={() =>
                      this.updateField([
                        [
                          'Party',
                          'Company',
                          this.state.hazInsCompany,
                          'hazInsCompany',
                          'hazIns',
                          'CategoryID = 31',
                        ],
                      ])
                    }
                  >
                    {this.state.hazInsCompanyUpdated ? '✓' : 'Update'}
                  </button>
                </div>
                <div className="input-group">
                  <span className="input-group-text">Start Date:</span>
                  <input
                    type="date"
                    name="hazInsStart"
                    className="form-control"
                    disabled={checkList.hazard.checked}
                    value={this.state.hazInsStart || ''}
                    onChange={this.captureInput}
                  />
                  <button
                    className="btn btn-primary btn-sm"
                    disabled={
                      this.state.originalHazInsStart ===
                        this.state.hazInsStart ||
                      checkList.hazard.checked
                    }
                    onClick={() =>
                      this.updateDynamicDataField([
                        [
                          'Haz Ins Start',
                          '',
                          this.state.hazInsStart,
                          'hazard',
                          'hazInsStart',
                        ],
                      ])
                    }
                  >
                    {this.state.hazInsStartUpdated ? '✓' : 'Update'}
                  </button>
                </div>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="ins1"
                      name="insExp"
                      checked={!this.state.insExp}
                      onChange={() => void 0}
                      disabled={checkList.hazard.checked}
                      onClick={() => {
                        this.setState({ insExp: false });
                        this.updateDynamicDataField([
                          ['InsExp', '', '0', '', ''],
                        ]);
                      }}
                    />
                    <label htmlFor="ins1" className="form-check-label">
                      Annual
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="ins2"
                      name="insExp"
                      checked={this.state.insExp}
                      onChange={() => void 0}
                      disabled={checkList.hazard.checked}
                      onClick={() => {
                        this.setState({ insExp: true });
                        this.updateDynamicDataField([
                          ['InsExp', '', '1', '', ''],
                        ]);
                      }}
                    />
                    <label htmlFor="ins2" className="form-check-label">
                      Manual
                    </label>
                  </div>
                </div>
                {hazInsExp && this.state.hazInsStart && (
                  <div className="input-group">
                    {this.state.insExp ? (
                      <React.Fragment>
                        <span className="input-group-text">Exp Date:</span>
                        <input
                          type="date"
                          name="hazInsEnd"
                          disabled={checkList.hazard.checked}
                          value={this.state.hazInsEnd || ''}
                          onChange={this.captureInput}
                        />
                        <button
                          className="btn btn-primary btn-sm"
                          disabled={
                            this.state.originalHazInsEnd ===
                              this.state.hazInsEnd ||
                            checkList.hazard.checked
                          }
                          onClick={() =>
                            this.updateDynamicDataField([
                              [
                                'Haz Ins End',
                                '',
                                this.state.hazInsEnd,
                                'hazard',
                                'hazInsEnd',
                              ],
                            ])
                          }
                        >
                          {this.state.hazInsEndUpdated ? '✓' : 'Update'}
                        </button>
                      </React.Fragment>
                    ) : (
                      <span
                        style={{
                          color: hazInsExp.length > 9 ? 'red' : 'black',
                        }}
                      >
                        Exp Date: {hazInsExp}
                      </span>
                    )}
                  </div>
                )}
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="h06"
                    checked={this.state.ho6w}
                    disabled={checkList.hazard.checked}
                    onChange={e => {
                      this.updateDynamicDataField([
                        ['HO6W', '', e.target.checked ? '1' : '0', '', 'ho6w'],
                      ]);
                      this.setState({ ho6w: e.target.checked });
                    }}
                  />
                  <label className="form-check-label" htmlFor="h06">
                    HO6 Waived
                  </label>
                </div>
              </div>
            }
            alert={
              <React.Fragment>
                {this.state.hazInsStart && hazInsCheck && (
                  <AutoCheck passed={false} tip={hazInsCheck} />
                )}
                {checkList.hazard.checked && (
                  <SignOff
                    user={checkList.hazard.userName}
                    date={checkList.hazard.timestamp}
                  />
                )}
              </React.Fragment>
            }
          />

          {/* Flood Insurance */}
          {this.state.fldZone && (
            <ChecklistCard
              checked={checkList.flood.checked}
              label={
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="flood"
                    id="flood"
                    disabled={
                      !this.state.fldInsAmount ||
                      this.state.originalFldInsAmount !==
                        this.state.fldInsAmount
                    }
                    checked={checkList.flood.checked}
                    onChange={this._toggleCheckbox}
                  />
                  <label className="form-check-label" htmlFor="flood">
                    Flood Insurance
                  </label>
                </div>
              }
              body={
                <div>
                  <div className="input-group">
                    <span className="input-group-text">Cost/Month:</span>
                    <input
                      type="text"
                      placeholder="$"
                      name="fldInsAmount"
                      className="form-control"
                      disabled={checkList.flood.checked}
                      value={this.state.fldInsAmount || ''}
                      onChange={this.captureInput}
                    />
                    <button
                      className="btn btn-primary btn-sm"
                      disabled={
                        unformatCurrency(this.state.originalFldInsAmount) ===
                          unformatCurrency(this.state.fldInsAmount) ||
                        checkList.flood.checked
                      }
                      onClick={() =>
                        this.updateField([
                          [
                            'PrepaidItem',
                            'Payment',
                            unformatCurrency(this.state.fldInsAmount),
                            'fldInsAmount',
                            'flood',
                            'PrepaidItemType = 6',
                          ],
                        ])
                      }
                    >
                      {this.state.fldInsAmountUpdated ? '✓' : 'Update'}
                    </button>
                  </div>
                  {fileData.programCode.includes('MTGP') && (
                    <div>
                      <Verify tip="For Non-QM Programs, Investor Approval Required" />
                      Investor Approval
                    </div>
                  )}
                </div>
              }
              alert={
                <React.Fragment>
                  {this.state.fldInsStart && fldInsCheck && (
                    <AutoCheck passed={false} tip={fldInsCheck} />
                  )}
                  {checkList.flood.checked && (
                    <SignOff
                      user={checkList.flood.userName}
                      date={checkList.flood.timestamp}
                    />
                  )}
                </React.Fragment>
              }
            />
          )}

          {/* Title Report */}
          <ChecklistCard
            checked={checkList.title.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="title"
                  id="title"
                  checked={checkList.title.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="title">
                  Title Report
                </label>
                <br />
                {this.state.prelimDocID && (
                  <table width="100%">
                    <tbody>
                      <tr>
                        <td style={{ textAlign: 'center' }}>
                          <i
                            className="bi bi-download"
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              this.downloadLoanFile(this.state.prelimDocID)
                            }
                          ></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div className="input-group">
                  <span className="input-group-text">Issue Date:</span>
                  <input
                    type="date"
                    className="form-control"
                    name="titleStartDate"
                    value={this.state.titleStartDate || ''}
                    disabled={checkList.title.checked}
                    onChange={this.captureInput}
                  />
                  <button
                    className="btn btn-primary btn-sm"
                    disabled={
                      this.state.originalTitleStartDate ===
                        this.databaseDateFormat(this.state.titleStartDate) ||
                      checkList.title.checked
                    }
                    onClick={() =>
                      this.updateField([
                        [
                          'PartyMisc',
                          'TitleReportDate',
                          this.databaseDateFormat(this.state.titleStartDate),
                          'titleStartDate',
                          '',
                        ],
                      ])
                    }
                  >
                    {this.state.titleStartDateUpdated ? '✓' : 'Update'}
                  </button>
                </div>

                {titleExpDate && (
                  <div>
                    Exp Date:&nbsp;
                    <span
                      style={{
                        color: titleExpDate.length > 9 ? 'red' : 'black',
                      }}
                    >
                      {titleExpDate}
                    </span>
                  </div>
                )}
                {fileData.loanPurpose.includes('Refinance') && (
                  <React.Fragment>
                    <div>
                      <Verify tip="For Refinances" />
                      Additional Liens (HELOC, CalHFA, HUD, etc)
                    </div>
                    <div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="lien1"
                          name="lien"
                          checked={this.state.lien === '0'}
                          disabled={checkList.title.checked}
                          onChange={() => void 0}
                          onClick={() => {
                            this.setState({ lien: '0' });
                            this.updateDynamicDataField([
                              ['Lien', '', '0', '', ''],
                            ]);
                          }}
                        />
                        <label htmlFor="lien1" className="form-check-label">
                          None
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="lien2"
                          name="lien"
                          checked={this.state.lien === '1'}
                          disabled={checkList.title.checked}
                          onChange={() => void 0}
                          onClick={() => {
                            this.setState({ lien: '1' });
                            this.updateDynamicDataField([
                              ['Lien', '', '1', '', ''],
                            ]);
                          }}
                        />
                        <label htmlFor="lien2" className="form-check-label">
                          Subordinated
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="lien3"
                          name="lien"
                          disabled={checkList.title.checked}
                          checked={this.state.lien === '2'}
                          onChange={() => void 0}
                          onClick={() => {
                            this.setState({ lien: '2' });
                            this.updateDynamicDataField([
                              ['Lien', '', '2', '', ''],
                            ]);
                          }}
                        />
                        <label htmlFor="lien3" className="form-check-label">
                          Removed
                        </label>
                      </div>
                    </div>
                  </React.Fragment>
                )}
                <div className="input-group">
                  <span className="input-group-text">#'s To Include:</span>
                  <input
                    type="text"
                    name="prelimItem"
                    className="form-control"
                    disabled={checkList.title.checked}
                    value={this.state.prelimItem || ''}
                    onChange={this.captureInput}
                  />
                  <button
                    className="btn btn-primary btn-sm"
                    disabled={
                      this.state.originalPrelimItem === this.state.prelimItem ||
                      checkList.title.checked
                    }
                    onClick={() =>
                      this.updateField([
                        [
                          'PartyMisc',
                          'TitleReportItems',
                          this.state.prelimItem,
                          'prelimItem',
                          'prelimItemNo',
                        ],
                      ])
                    }
                  >
                    {this.state.prelimItemUpdated ? '✓' : 'Update'}
                  </button>
                </div>

                {fileData.loanPurpose.includes('R/T') && (
                  <React.Fragment>
                    <div>
                      <span style={{ marginRight: '15px' }}>Vesting:</span>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="vesting1"
                          name="vesting"
                          disabled={checkList.title.checked}
                          checked={!this.state.vesting}
                          onChange={() => void 0}
                          onClick={() => {
                            this.setState({ vesting: 0 });
                            this.updateDynamicDataField([
                              ['Vesting', '', '0', '', ''],
                            ]);
                          }}
                        />
                        <label htmlFor="vesting1" className="form-check-label">
                          Same
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="vesting2"
                          name="vesting"
                          checked={this.state.vesting}
                          disabled={checkList.title.checked}
                          onChange={() => void 0}
                          onClick={() => {
                            this.setState({ vesting: 1 });
                            this.updateDynamicDataField([
                              ['Vesting', '', '1', '', ''],
                            ]);
                          }}
                        />
                        <label htmlFor="vesting2" className="form-check-label">
                          Different
                        </label>
                      </div>
                    </div>
                    <div>
                      <Verify tip="For R/T Refinances" />
                      Grant Deed
                    </div>
                  </React.Fragment>
                )}

                {fileData.loanPurpose.includes('C/O') && (
                  <React.Fragment>
                    <div>
                      <Verify tip="For C/O Refinances" />
                      24 Month Reconveyance History
                    </div>
                    <div>
                      <Verify tip="For C/O Refinances" />6 Months Seasoning
                    </div>
                  </React.Fragment>
                )}
              </div>
            }
            alert={
              checkList.title.checked && (
                <SignOff
                  user={checkList.title.userName}
                  date={checkList.title.timestamp}
                />
              )
            }
          />

          {/* Purchase Contract */}
          {fileData.loanPurpose.includes('Purchase') && (
            <ChecklistCard
              checked={checkList.purchaseContract.checked}
              label={
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="purchaseContract"
                    id="purchaseContract"
                    checked={checkList.purchaseContract.checked}
                    onChange={this._toggleCheckbox}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="purchaseContract"
                  >
                    Purchase Contract
                  </label>
                </div>
              }
              body={
                <div>
                  <Verify />
                  All Pages Initialed, Signed, & Addendums
                </div>
              }
              alert={
                checkList.purchaseContract.checked && (
                  <SignOff
                    user={checkList.purchaseContract.userName}
                    date={checkList.purchaseContract.timestamp}
                  />
                )
              }
            />
          )}

          {/* Escrow Instructions */}
          <ChecklistCard
            checked={
              checkList.escrowInstructions.checked || this.state.escrowToFunding
            }
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="escrowInstructions"
                  id="escrowInstructions"
                  checked={
                    checkList.escrowInstructions.checked ||
                    this.state.escrowToFunding
                  }
                  onChange={this._toggleCheckbox}
                />
                <label
                  className="form-check-label"
                  htmlFor="escrowInstructions"
                >
                  Escrow Instructions
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <TableZero>
                    <tbody>
                      <tr>
                        <td>
                          <Verify />
                        </td>
                        <td>
                          All Signed, Finalized Loan Amt, <br />
                          Vesting Amendment
                        </td>
                      </tr>
                    </tbody>
                  </TableZero>
                </div>
                <div style={{ paddingLeft: '6px' }}>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="escrowToFunding"
                      checked={this.state.escrowToFunding}
                      onChange={e => {
                        this.updateDynamicDataField([
                          [
                            'Escrow To Funding',
                            '',
                            e.target.checked ? '1' : '0',
                            '',
                            'escrowToFunding',
                          ],
                        ]);
                        this.setState({ escrowToFunding: e.target.checked });
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="escrowToFunding"
                    >
                      Move to Funding
                    </label>
                  </div>
                </div>
              </div>
            }
            alert={
              <React.Fragment>
                {checkList.escrowInstructions.checked && (
                  <SignOff
                    user={checkList.escrowInstructions.userName}
                    date={checkList.escrowInstructions.timestamp}
                  />
                )}
                {this.state.escrowToFunding && (
                  <AutoCheck passed={true} tip="Escrow To Funding" />
                )}
              </React.Fragment>
            }
          />

          {/* Tax */}
          {this.state.originalMonthlyTax !== this.state.monthlyTax &&
            this.setFieldFalse('tax')}
          <ChecklistCard
            checked={checkList.tax.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="tax"
                  id="tax"
                  disabled={!this.state.originalMonthlyTax}
                  checked={checkList.tax.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="tax">
                  Tax
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div className="input-group">
                  <span className="input-group-text">Monthly:</span>
                  <input
                    type="text"
                    placeholder="$"
                    name="monthlyTax"
                    className="form-control"
                    disabled={checkList.tax.checked}
                    value={this.state.monthlyTax || ''}
                    onChange={this.captureInput}
                  />
                  <button
                    className="btn btn-primary btn-sm"
                    disabled={
                      unformatCurrency(this.state.originalMonthlyTax) ===
                        unformatCurrency(this.state.monthlyTax) ||
                      checkList.tax.checked
                    }
                    onClick={() =>
                      this.updateField([
                        [
                          'PrepaidItem',
                          'Payment',
                          unformatCurrency(this.state.monthlyTax),
                          'monthlyTax',
                          'tax',
                          'PrepaidItemType = 4',
                        ],
                      ])
                    }
                  >
                    {this.state.monthlyTaxUpdated ? '✓' : 'Update'}
                  </button>
                </div>
                {fileData.loanPurpose !== 'Purchase' && (
                  <div>
                    {' '}
                    <Verify tip="For Refinances" />
                    If Recently Purchased
                  </div>
                )}
                <div>
                  <Verify />
                  Calculation on 1008
                </div>
              </div>
            }
            alert={
              checkList.tax.checked && (
                <SignOff
                  user={checkList.tax.userName}
                  date={checkList.tax.timestamp}
                />
              )
            }
          />

          {/* Appraisal */}
          {this.state.waiver &&
            !this.state.appraisalValue &&
            checkList.appraisal.checked &&
            (checkList.appraisal.checked = false)}
          <ChecklistCard
            checked={checkList.appraisal.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="appraisal"
                  id="appraisal"
                  disabled={
                    this.state.appraisalToFunding ||
                    (!fileData.programCode.includes('MTGP') &&
                      this.state.waiver &&
                      (!this.state.originalAppraisalValue ||
                        this.state.appraisalValue !==
                          this.state.originalAppraisalValue))
                  }
                  checked={checkList.appraisal.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="appraisal">
                  {fileData.propertyType === 'Condo'
                    ? '1073'
                    : fileData.propertyType.includes(' Unit')
                    ? '1025'
                    : '1004'}{' '}
                  Appraisal
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <TableZero>
                  <tbody>
                    {fileData.programCode.includes('MTGP') ? (
                      <React.Fragment>
                        <tr>
                          <td colSpan={2}>
                            <Verify tip="For Non-QM" />
                            Desk Review
                          </td>
                        </tr>
                      </React.Fragment>
                    ) : (
                      <tr>
                        <td colSpan={2}>
                          <div>
                            <span
                              style={{
                                display: 'inline-block',
                                paddingRight: '15px',
                              }}
                            >
                              Waiver:
                            </span>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                id="waiver1"
                                name="waiver"
                                checked={!this.state.waiver}
                                onChange={() => void 0}
                                disabled={checkList.appraisal.checked}
                                onClick={() => {
                                  this.setState({ waiver: false });
                                  this.updateDynamicDataField([
                                    ['Waiver', '', '0', '', ''],
                                  ]);
                                }}
                              />
                              <label
                                htmlFor="waiver1"
                                className="form-check-label"
                                onClick={() => {
                                  this.setState({ waiver: false });
                                  this.updateDynamicDataField([
                                    ['Waiver', '', '0', '', ''],
                                  ]);
                                }}
                              >
                                PIW
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                id="waiver2"
                                name="waiver"
                                checked={this.state.waiver}
                                disabled={checkList.appraisal.checked}
                                onChange={() => void 0}
                                onClick={() => {
                                  this.setState({ waiver: true });
                                  this.updateDynamicDataField([
                                    ['Waiver', '', '1', '', ''],
                                  ]);
                                }}
                              />
                              <label
                                htmlFor="waiver2"
                                className="form-check-label"
                              >
                                Provide Value
                              </label>
                            </div>
                          </div>
                          {this.state.waiver && (
                            <div className="input-group mt-3">
                              <span className="input-group-text">Value:</span>
                              <input
                                type="text"
                                name="appraisalValue"
                                placeholder="$"
                                disabled={checkList.appraisal.checked}
                                className="form-control"
                                value={this.state.appraisalValue || ''}
                                onChange={this.captureInput}
                              />
                              <button
                                className="btn btn-primary btn-sm"
                                disabled={
                                  this.state.originalAppraisalValue ===
                                    this.state.appraisalValue ||
                                  checkList.appraisal.checked
                                }
                                onClick={() =>
                                  this.updateDynamicDataField([
                                    [
                                      'Appraisal Value',
                                      '',
                                      this.state.appraisalValue,
                                      'appraisal',
                                      'appraisalValue',
                                    ],
                                  ])
                                }
                              >
                                {this.state.appraisalValueUpdated
                                  ? '✓'
                                  : 'Update'}
                              </button>{' '}
                            </div>
                          )}
                        </td>
                      </tr>
                    )}
                    {(fileData.propertyType.includes(' Unit') ||
                      fileData.occupancyType === 'Investment') && (
                      <React.Fragment>
                        <tr height="15px" />
                        <tr>
                          <td colSpan={2}>
                            <Verify tip="For Multi-Unit Properties" /> Income
                            Statement (Form 216)
                          </td>
                        </tr>
                      </React.Fragment>
                    )}
                    {fileData.occupancyType === 'Investment' &&
                      !fileData.propertyType.includes(' Unit') && (
                        <React.Fragment>
                          <tr height="15px" />
                          <tr>
                            <td colSpan={2}>
                              <Verify tip="For Single-Unit Investment Homes" />{' '}
                              Rent Schedule (Form 1007)
                            </td>
                          </tr>
                        </React.Fragment>
                      )}
                    {fileData.programCode.includes('MTGP') && (
                      <React.Fragment>
                        <tr height="15px" />
                        <tr>
                          <td colSpan={2} style={{ paddingLeft: '6px' }}>
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="escrowToFunding"
                                disabled={checkList.appraisal.checked}
                                checked={this.state.appraisalToFunding}
                                onChange={e => {
                                  this.updateDynamicDataField([
                                    [
                                      'Appraisal To Funding',
                                      '',
                                      e.target.checked ? '1' : '0',
                                      '',
                                      'appraisalToFunding',
                                    ],
                                  ]);
                                  this.setState({
                                    appraisalToFunding: e.target.checked,
                                  });
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="escrowToFunding"
                              >
                                Move to Funding
                              </label>
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    )}
                  </tbody>
                </TableZero>
              </div>
            }
            alert={
              checkList.appraisal.checked && (
                <SignOff
                  user={checkList.appraisal.userName}
                  date={checkList.appraisal.timestamp}
                />
              )
            }
          />

          {/* AUS */}
          {(fileData.programCode.includes('DU') ||
            fileData.programCode.includes('LP')) && (
            <ChecklistCard
              checked={checkList.aus.checked}
              label={
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="aus"
                    id="aus"
                    disabled={
                      !this.state.originalDtiMatched ||
                      this.state.originalDtiMatched !== this.state.dtiMatched
                    }
                    checked={checkList.aus.checked}
                    onChange={this._toggleCheckbox}
                  />
                  <label className="form-check-label" htmlFor="aus">
                    AUS
                  </label>
                </div>
              }
              body={
                <div className="vstack gap-3">
                  <div>
                    {' '}
                    <Verify />
                    DU/LP Approved/Eligible
                  </div>
                  <div className="input-group">
                    <span className="input-group-text">DTI Matched:</span>
                    <input
                      type="text"
                      name="dtiMatched"
                      className="form-control"
                      placeholder="%"
                      disabled={checkList.aus.checked}
                      value={this.state.dtiMatched || ''}
                      onChange={this.captureInput}
                    />
                    <button
                      className="btn btn-primary btn-sm"
                      disabled={
                        this.state.originalDtiMatched ===
                          this.state.dtiMatched || checkList.aus.checked
                      }
                      onClick={() =>
                        this.updateDynamicDataField([
                          [
                            'DTI Matched',
                            '',
                            this.state.dtiMatched,
                            'aus',
                            'dtiMatched',
                          ],
                        ])
                      }
                    >
                      {this.state.dtiMatchedUpdated ? '✓' : 'Update'}
                    </button>
                  </div>
                </div>
              }
              alert={
                checkList.aus.checked && (
                  <SignOff
                    user={checkList.aus.userName}
                    date={checkList.aus.timestamp}
                  />
                )
              }
            />
          )}

          {/* Liabilities */}
          <ChecklistCard
            checked={checkList.liabilities.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="liabilities"
                  id="liabilities"
                  checked={checkList.liabilities.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="liabilities">
                  Liabilities
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                {fileData.loanPurpose.includes('R/T') && (
                  <div>
                    <TableZero>
                      <tbody>
                        <tr>
                          <td>
                            <Verify tip="For R/T Refinances" />
                          </td>
                          <td>
                            Any Liabilities To Be Paid With <br />
                            Borrower’s Own Funds
                          </td>
                        </tr>
                      </tbody>
                    </TableZero>
                  </div>
                )}
                <div>
                  <Verify tip="Liabilities Marked: TBPO" />
                  Liabilities Paid Off Through Closing
                  {/* {(this.state.liabilitiesTbpo && this.state.liabilitiesTbpo.length > 0) ? (<React.Fragment>
                    {this.state.liabilitiesTbpo.map(liability => (
                      <div>
                      </div>
                    ))}
                  </React.Fragment>) : (<React.Fragment>
                    <div></div>
                  </React.Fragment>)} */}
                </div>
                <div>
                  <Verify tip="Liabilities Marked: NC" />
                  Liabilities Excluded
                  {/* {(this.state.liabilitiesNc && this.state.liabilitiesNc.length > 0) ? (<React.Fragment>
                    {this.state.liabilitiesNc.map(liability => (
                      <div>
                      </div>
                    ))}
                  </React.Fragment>) : (<React.Fragment>
                    <div>  </div>
                  </React.Fragment>)} */}
                </div>
              </div>
            }
            alert={
              checkList.liabilities.checked && (
                <SignOff
                  user={checkList.liabilities.userName}
                  date={checkList.liabilities.timestamp}
                />
              )
            }
          />

          {/* Vesting */}
          {this.state.trustType === "2" && (
          <ChecklistCard
            checked={checkList.vesting.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="vesting"
                  id="vesting"
                  checked={checkList.vesting.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="vesting">
                  Vesting in Trust
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <Verify />
                  Check All Necessary Trust Documents
                </div>
              </div>
            }
            alert={
              checkList.vesting.checked && (
                <SignOff
                  user={checkList.vesting.userName}
                  date={checkList.vesting.timestamp}
                />
              )
            }
          />
          )}

          {/* Change Status (Clear to Close) */}
          <ChecklistCard
            checked={this.state.fileData.ctcDate !== '---'}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="changeCtc"
                  name="changeCtc"
                  disabled={true}
                  checked={this.state.fileData.ctcDate !== '---'}
                />
                <label className="form-check-label" htmlFor="changeCtc">
                  Clear to Close
                </label>
              </div>
            }
            body={
              <div>
                <Verify tip="Change Loan Status to 'Clear to Close'" />
                {this.state.fileData.ctcDate !== '---' ? (
                  <React.Fragment>
                    <span
                      className={`fw-normal badge border rounded-pill`}
                      style={{
                        marginRight: '8px',
                        color: 'black',
                        backgroundColor: STATUS_COLORS['clear to close'],
                      }}
                    >
                      Clear to Close
                    </span>
                    {this.state.fileData.ctcDate}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <span
                      className={`fw-normal badge border rounded-pill`}
                      style={{
                        marginRight: '8px',
                        color: [
                          'clear to close',
                          'clear to fund',
                          'in post-funding',
                          'on hold',
                        ].includes(this.state.fileData.loanStatus.toLowerCase())
                          ? 'white'
                          : 'black',
                        backgroundColor:
                          STATUS_COLORS[
                            this.state.fileData.loanStatus.toLowerCase()
                          ],
                      }}
                    >
                      {this.state.fileData.loanStatus}
                    </span>
                    <button
                      className="btn btn-sm btn-outline-primary ms-2"
                      style={{ width: '65px' }}
                      disabled={
                        this.state.changingState ||
                        this.state.fileData.ctcDate !== '---'
                      }
                      onClick={() => this.setLoanStatus('Clear to Close')}
                    >
                      {this.state.changingState === 'Clear to Close'
                        ? '...'
                        : 'Change'}
                    </button>
                  </React.Fragment>
                )}
              </div>
            }
            alert={
              <AutoCheck
                passed={this.state.fileData.ctcDate !== '---'}
                tip="Clear to Close Date Not Found"
              />
            }
          />

          {/* CTC Email */}
          <ChecklistCard
            checked={checkList.ctcEmail.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="ctcEmail"
                  name="ctcEmail"
                  checked={checkList.ctcEmail.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="ctcEmail">
                  Send Email
                </label>
              </div>
            }
            body={
              <div>
                <div>
                  <Email />
                  CTCed
                  <button
                    className="btn btn-sm btn-outline-primary ms-2"
                    onClick={() => {
                      this.setState({
                        showEmailTemplate: true,
                        emailTemplate: this.state.ctcTemplate,
                        emailLog: this.state.ctcEmailLog,
                      });
                    }}
                  >
                    Preview
                  </button>
                  {this.state.ctcEmailLog.length > 0 && (
                    <button
                      className="btn"
                      type="button"
                      style={{
                        height: '24px',
                        maxWidth: '24px',
                        marginLeft: '10px',
                        pointerEvents: 'auto',
                      }}
                      onClick={() =>
                        this.setState({
                          showEmailLog: true,
                          emailLog: this.state.ctcEmailLog,
                        })
                      }
                    >
                      <i
                        className="bi bi-envelope-check"
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          position: 'relative',
                          top: '-10px',
                          left: '-10px',
                        }}
                      />
                    </button>
                  )}
                </div>
              </div>
            }
            alert={
              checkList.ctcEmail.checked && (
                <SignOff
                  user={checkList.ctcEmail.userName}
                  date={checkList.ctcEmail.timestamp}
                />
              )
            }
          />

          {this.state.checkList.complete.checked && this.allChecked() ? (
            <div className="text-center mt-4">
              <button
                className="btn btn-primary text-white"
                style={{ backgroundColor: '#84c488', borderColor: 'gray' }}
                disabled={true}
              >
                Completed ✓
              </button>
            </div>
          ) : (
            <div className="text-center mt-4">
              <button
                className="btn btn-primary text-white"
                onClick={() => this.markComplete()}
                disabled={
                  !this.allChecked() || this.state.checkList.complete.checked
                }
              >
                {this.allChecked() ? 'Mark Complete' : 'Not Completed'}
              </button>
            </div>
          )}
        </div>

        {this.state.errorMessage && (
          <Toast
            type="danger"
            title={this.state.errorMessage}
            onClose={() => this.setState({ errorMessage: '' })}
          />
        )}
        {this.state.successMessage && (
          <Toast
            type="success"
            title={this.state.successMessage}
            onClose={() => this.setState({ successMessage: '' })}
          />
        )}

        <ModalEmail
          show={this.state.showEmailTemplate}
          template={this.state.emailTemplate}
          fileID={this.state.activeFile}
          emailLog={this.state.emailLog}
          onDecline={() =>
            this.setState({ showEmailTemplate: false, emailTemplate: [] })
          }
          onAccept={() =>
            this.setState({ showEmailTemplate: false, emailTemplate: [] })
          }
        />
        <ModalEmailLog
          show={this.state.showEmailLog}
          emailLog={this.state.emailLog}
          onDecline={() => this.setState({ showEmailLog: false, emailLog: [] })}
        />
        <ModalConfirm
          show={this.state.showConfirmModal || this.state.showClearModal}
          onAccept={() => this.setCheckbox(this.state.showConfirmModal)}
          onDecline={() =>
            this.setState({ showConfirmModal: false, showClearModal: false })
          }
        >
          <div style={{ fontSize: '16px' }}>
            <b>{this.state.showConfirmModal ? 'Set' : 'Clear'} Checkbox:</b>
            <span style={{ marginLeft: '10px' }}>
              "{FindLabelFromName(this.state.confirmTitle)}"
            </span>
          </div>
        </ModalConfirm>
        <ModalConfirm
          show={this.state.showClearAllModal}
          onAccept={() => this.ClearAll()}
          onDecline={() => this.setState({ showClearAllModal: false })}
        >
          <div style={{ fontSize: '16px', textAlign: 'center' }}>
            <b>Are You Sure You Want to Clear <u>ALL</u> <br/>
            Checkbox Fields For This Checklist?</b>
          </div>
        </ModalConfirm>
      </React.Fragment>
    );
  }
}
