import React, { useState } from 'react';
import axios from 'axios';
import { DROPDOWN_VALUES, PRICING_OPTIONS } from '../../helpers/constants';
import { getCLTV, getLTV, useFormFields, formatCurrency } from '../../helpers/utils';
import FormInput from '../FormInput';
import FormInputCurrency from '../FormInputCurrency';
import FormInputPercentage from '../FormInputPercentage';
import FormSelect from '../FormSelect';
import ViewElement from '../ViewElement';
import { isEmpty } from 'lodash';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import LoginState from '../LoginState';

const Placeholder = () => (
  <div>
    <i
      className="bi bi-cloud-upload text-muted"
      style={{ fontSize: '3rem' }}
    ></i>
    <h5 className="mb-1 text-muted">
      Drag and Drop .PDF Files or Click Below to Upload
    </h5>
    <h6 className="text-muted">
      (The System Will Only Accept Up to 20 Mb of Files at a Time)
    </h6>
  </div>
);

const FNMImport = ({ changeStep, loan, fnmFile }) => {
  // define initial loan;
  const [loanFile, setLoan] = useState(null);
  const [fnmFileRead, setFnmFile] = useState(null);
  const [fields, handleFieldChange] = useFormFields({});
  const [errors, updateErrors] = useState({});
  const [resetClicked, setRestart] = useState(null);
  const [cancelledWarning, setCancelledWarning] = useState(null);

  var COMP_VALUES = {
    options: [
      { label: 'Borrower Paid', value: 1 },
      { label: 'Lender Paid (' + LoginState.compPlan + ')', value: 2 },
    ]
  };

  // validate form inputs
  const validate = () => {
    let newErrors = {};
    [
      'fico',
      'dti',
      'purchasePrice',
      'originationFee',
      'adminFee',
      'processingFee',
    ].forEach(name => {
      if (!fields[name]) {
        newErrors[name] = 'required';
      }
    });

    [
      'propertyType',
      'impound',
      'compPlan',
      'documentType',
      'product',
      'occupancyType',
      'pmi',
      'county',
      'eDisclosure',
      'ausOnly',
    ].forEach(name => {
      if (!fields[name] || fields[name] == "0") {
        newErrors[name] = 'required';
      }
    });

    if(fields['loanPurpose'] == "0" || fields['loanPurpose'] == "-1")
      newErrors['loanPurpose'] = 'required';

    // if full doc ARM
    if (
      fields.documentType.toString() === '1' &&
      loanFile.amortizationType !== 5 &&
      !fields['product']
    ) {
      newErrors['product'] = 'required';
    }

    if (Object.keys(newErrors).length > 0) {
      updateErrors(newErrors);
      return;
    } else {
      handleLoanSubmit();
    }
  };

  const checkForCancelled = async (address, ssns) => {
    const response = axios.get('/checkcancelled', {
      params: {
        pAddressZip: address,
        pSsns: ssns
      },
    });
    var resultData = (await response).data;
    console.log(resultData);
    if(resultData) {
      setCancelledWarning(resultData);
    }
  }

  const handleLoanSubmit = () => {
    let fullLoan = { ...loanFile };

    let ltv = getLTV(loanFile);
    try {
      ltv = ltv.substring(0, ltv.length - 1);
    } catch {
      ltv = 0;
    }

    let cltv = getCLTV(loanFile);
    try {
      cltv = cltv.substring(0, cltv.length - 1);
    } catch {
      cltv = 0;
    }

    fullLoan.ltv = parseInt(ltv);
    fullLoan.cltv = parseInt(cltv);
    fullLoan.borrowers[0].fico = fields.fico ?? 0;
    fullLoan.borrowers[0].dti = fields.dti;
    fullLoan.eDisclosure = fields.eDisclosure;
    fullLoan.documentType = fields.documentType;
    fullLoan.purchasePrice = fields.purchasePrice;
    fullLoan.county = fields.county;
    fullLoan.impound = fields.impound;
    fullLoan.compPlan = fields.compPlan;
    fullLoan.pmi = fields.pmi;
    fullLoan.ausOnly = fields.ausOnly;

    fullLoan.purpose = fields.loanPurpose;
    fullLoan.product =
      parseInt(fields.product) === 0
        ? ''
        : parseInt(fields.product) < 9
        ? PRICING_OPTIONS.loanProduct[parseInt(fields.product) - 1].label
        : PRICING_OPTIONS.loanProductPortfolio[parseInt(fields.product) - 9]
            .label;
    fullLoan.occupancyType = fields.occupancyType;
    fullLoan.propertyType = fields.propertyType;
    fullLoan.originationFee = fields.originationFee;
    fullLoan.adminFee = fields.adminFee;
    fullLoan.processingFee = fields.processingFee;

    changeStep('select', fullLoan, fnmFileRead);
  };

  // will let us start over by clearing all previous inputs
  const restartImport = () => {
    setLoan(null);
    setFnmFile(null);
    setRestart(true);
    setCancelledWarning(null);

    // clear fields
    fields.fico = '';
    fields.dti = '';
    fields.eDisclosure = '';
    fields.documentType = '';
    fields.purchasePrice = '';
    fields.county = '';
    fields.impound = '';
    fields.compPlan = '';
    fields.pmi = '';
    fields.ausOnly = 2;
    fields.loanPurpose = 0;
    fields.product = 0;
    fields.occupancyType = 0;
    fields.propertyType = 0;
    fields.originationFee = '';
    fields.adminFee = (LoginState.orgType === 'Wholesale') ? 0 : '';
    fields.processingFee = (LoginState.orgType === 'Wholesale') ? LoginState.flatFee : '';
  };

  const parseFnmFile = ({ file }, status) => {
    let fnmFileRead = '';
    let newLoan = { borrowers: [] };
    if (status === 'done') {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = e => {
        var parseString = require('xml2js').parseString;
        parseString(e.target.result, function (err, parsedXml) {
          try {
            parseXmlToLoanData(parsedXml, newLoan);
          } catch (e) {
            console.log(e);
            newLoan.parseError =
              'An Unexpected Error Occurred While Parsing The File!\n' +
              'Please Notify MTGRL and We Will Look Into Resolving the Issue!';
          }
        });
        fnmFileRead = e.target.result;
      };
      reader.onloadend = () => {
        setLoan(newLoan);
        setFnmFile(fnmFileRead);
      };
    }
  };

  const parseXmlToLoanData = (xml, loan) => {
    // I. Mortgage
    if(!xml.MESSAGE.DEAL_SETS || !xml.MESSAGE.DEAL_SETS[0] || !xml.MESSAGE.DEAL_SETS[0].DEAL_SET || !xml.MESSAGE.DEAL_SETS[0].DEAL_SET[0] || 
      !xml.MESSAGE.DEAL_SETS[0].DEAL_SET[0].DEALS[0].DEAL || !xml.MESSAGE.DEAL_SETS[0].DEAL_SET[0].DEALS[0].DEAL[0] || 
      !xml.MESSAGE.DEAL_SETS[0].DEAL_SET[0].DEALS[0].DEAL[0].LOANS || !xml.MESSAGE.DEAL_SETS[0].DEAL_SET[0].DEALS[0].DEAL[0].LOANS[0] ||
      !xml.MESSAGE.DEAL_SETS[0].DEAL_SET[0].DEALS[0].DEAL[0].LOANS[0].LOAN || !xml.MESSAGE.DEAL_SETS[0].DEAL_SET[0].DEALS[0].DEAL[0].LOANS[0].LOAN[0]) {
      loan.parseError = 'Error: Missing <Loan> Node in XML File!';
      return;
    }  
    const loanNode =
      xml.MESSAGE.DEAL_SETS[0].DEAL_SET[0].DEALS[0].DEAL[0].LOANS[0].LOAN[0];
    if(!loanNode.TERMS_OF_LOAN || !loanNode.TERMS_OF_LOAN[0] || !loanNode.TERMS_OF_LOAN[0].LoanPurposeType || !loanNode.TERMS_OF_LOAN[0].LoanPurposeType[0]) {
      loan.parseError = 'Error: Missing <LoanPurposeType> Node in XML File!';
      return;
    }
    const loanPurp = loanNode.TERMS_OF_LOAN[0].LoanPurposeType[0];

    if(!loanNode.TERMS_OF_LOAN || !loanNode.TERMS_OF_LOAN[0]) {
      loan.parseError = 'Error: Missing <TERMS_OF_LOAN> Node in XML File!';
      return;
    }
    if(!loanNode.TERMS_OF_LOAN[0].LoanPurposeType || !loanNode.TERMS_OF_LOAN[0].LoanPurposeType[0]) {
      loan.parseError = 'Error: Missing <LoanPurposeType> Node in XML File!';
      return;
    }
    loan.amount = loanNode.TERMS_OF_LOAN[0].BaseLoanAmount[0];
    if(!loanNode.TERMS_OF_LOAN[0].NoteRatePercent || !loanNode.TERMS_OF_LOAN[0].NoteRatePercent[0]) {
      loan.parseError = 'Error: Missing <NoteRatePercent> Node in XML File!';
      return;
    }
    loan.interestRate = loanNode.TERMS_OF_LOAN[0].NoteRatePercent[0];

    if(!loanNode.AMORTIZATION || !loanNode.AMORTIZATION[0] || !loanNode.AMORTIZATION[0].AMORTIZATION_RULE || !loanNode.AMORTIZATION[0].AMORTIZATION_RULE[0]) {
      loan.parseError = 'Error: Missing <AMORTIZATION>/<AMORTIZATION_RULE> Node in XML File!';
      return;
    }
    if(!loanNode.AMORTIZATION[0].AMORTIZATION_RULE[0].LoanAmortizationPeriodCount || !loanNode.AMORTIZATION[0].AMORTIZATION_RULE[0].LoanAmortizationPeriodCount[0]) {
      loan.parseError = 'Error: Missing <LoanAmortizationPeriodCount> Node in XML File!';
      return;
    }
    loan.term = parseInt(
      loanNode.AMORTIZATION[0].AMORTIZATION_RULE[0]
        .LoanAmortizationPeriodCount[0]
    );
    if(!loanNode.AMORTIZATION[0].AMORTIZATION_RULE[0].AmortizationType || !loanNode.AMORTIZATION[0].AMORTIZATION_RULE[0].AmortizationType[0]) {
      loan.parseError = 'Error: Missing <AmortizationType> Node in XML File!';
      return;
    }
    loan.amortizationType =
      loanNode.AMORTIZATION[0].AMORTIZATION_RULE[0].AmortizationType[0] ===
      'AdjustableRate'
        ? 1
        : 5;
    if(!loanNode.TERMS_OF_LOAN || !loanNode.TERMS_OF_LOAN[0] || !loanNode.TERMS_OF_LOAN[0].LienPriorityType || !loanNode.TERMS_OF_LOAN[0].LienPriorityType[0]) {
      loan.parseError = 'Error: Missing <TERMS_OF_LOAN>/<LienPriorityType> Node in XML File!';
      return;
    }
    loan.lienPosition =
      loanNode.TERMS_OF_LOAN[0].LienPriorityType[0] === 'FirstLien' ? 1 : 2;
    if (loanPurp === 'Refinance' && !loanNode.REFINANCE) {
      loan.parseError = 'Error: Type of Refinance Not Specified in XML File!';
      return;
    }

    if(loanPurp === 'Purchase') { loan.purpose = 'PUR'; }
    else {
      if (!loanNode.REFINANCE || !loanNode.REFINANCE[0] || !loanNode.REFINANCE[0].RefinanceCashOutDeterminationType || !loanNode.REFINANCE[0].RefinanceCashOutDeterminationType[0]) {
        // previously we were throwing an error, but now just skipping this node.
      }
      else {
        switch(loanNode.REFINANCE[0].RefinanceCashOutDeterminationType[0]) {
          case 'LimitedCashOut':
            loan.purpose = 'R/T';
            break;
          case 'CashOut':
            loan.purpose = 'C/O (Other)';
            break;
          default:
            loan.purpose = 'C/O (Other)';
            break;
        }
      }
    }

    // II. Property
    var propertyNode = null;
    var propertyAddress = null;
    var propertyDetail = null;
    var contractDetail = null;
    var structureType = null;
    var unitIdentifier = null;

    try {
      if (!xml.MESSAGE.DEAL_SETS || !xml.MESSAGE.DEAL_SETS[0] || !xml.MESSAGE.DEAL_SETS[0].DEAL_SET || !xml.MESSAGE.DEAL_SETS[0].DEAL_SET[0] ||
        !xml.MESSAGE.DEAL_SETS[0].DEAL_SET[0].DEALS[0].DEAL || !xml.MESSAGE.DEAL_SETS[0].DEAL_SET[0].DEALS[0].DEAL[0] ||
        !xml.MESSAGE.DEAL_SETS[0].DEAL_SET[0].DEALS[0].DEAL[0].COLLATERALS || !xml.MESSAGE.DEAL_SETS[0].DEAL_SET[0].DEALS[0].DEAL[0].COLLATERALS[0] ||
        !xml.MESSAGE.DEAL_SETS[0].DEAL_SET[0].DEALS[0].DEAL[0].COLLATERALS[0].COLLATERAL || !xml.MESSAGE.DEAL_SETS[0].DEAL_SET[0].DEALS[0].DEAL[0].COLLATERALS[0].COLLATERAL[0] ||
        !xml.MESSAGE.DEAL_SETS[0].DEAL_SET[0].DEALS[0].DEAL[0].COLLATERALS[0].COLLATERAL[0].SUBJECT_PROPERTY || 
        !xml.MESSAGE.DEAL_SETS[0].DEAL_SET[0].DEALS[0].DEAL[0].COLLATERALS[0].COLLATERAL[0].SUBJECT_PROPERTY[0]) {
        loan.parseError = 'Error: Missing <SUBJECT_PROPERTY> Node in XML File!';
        return;
      }
      propertyNode =
        xml.MESSAGE.DEAL_SETS[0].DEAL_SET[0].DEALS[0].DEAL[0].COLLATERALS[0]
          .COLLATERAL[0].SUBJECT_PROPERTY[0];
      if(!propertyNode.ADDRESS || !propertyNode.ADDRESS[0]) {
        loan.parseError = 'Error: Missing <ADDRESS> Node in XML File!';
        return;
      }
      propertyAddress = propertyNode.ADDRESS[0];
      if(!propertyNode.PROPERTY_DETAIL || !propertyNode.PROPERTY_DETAIL[0]) {
        loan.parseError = 'Error: Missing <PROPERTY_DETAIL> Node in XML File!';
        return;
      }
      propertyDetail = propertyNode.PROPERTY_DETAIL[0];
      if(propertyNode.SALES_CONTRACTS && (!propertyNode.SALES_CONTRACTS[0] || !propertyNode.SALES_CONTRACTS[0].SALES_CONTRACT ||
        !propertyNode.SALES_CONTRACTS[0].SALES_CONTRACT[0] || !propertyNode.SALES_CONTRACTS[0].SALES_CONTRACT[0].SALES_CONTRACT_DETAIL)) {
        loan.parseError = 'Error: Missing <SALES_CONTRACT_DETAIL> Node in XML File!';
        return;
      }
      contractDetail = propertyNode.SALES_CONTRACTS
        ? propertyNode.SALES_CONTRACTS[0].SALES_CONTRACT[0]
            .SALES_CONTRACT_DETAIL
        : null;


      try {
        structureType = propertyNode.PROJECT
          ? propertyNode.PROJECT[0].PROJECT_DETAIL[0].ProjectLegalStructureType
            ? propertyNode.PROJECT[0].PROJECT_DETAIL[0]
                .ProjectLegalStructureType[0]
            : ''
          : '';
      }
      catch {
        loan.parseError = 'Error: Missing <PROJECT_DETAIL>/<ProjectLegalStructureType> Node in XML File!';
        return;
      }

      unitIdentifier = propertyAddress.AddressUnitIdentifier
        ? propertyAddress.AddressUnitIdentifier[0]
        : '';

      loan.property = {
        streetAddress: (propertyAddress.AddressLineText
          ? propertyAddress.AddressLineText[0] +
            (propertyAddress.AddressUnitIdentifier
              ? ' #' + propertyAddress.AddressUnitIdentifier[0]
              : '')
          : '---'
        ).toUpperCase(),
        city: propertyAddress.CityName
          ? propertyAddress.CityName[0].toUpperCase()
          : '---',
        county: propertyAddress.CountyName
          ? propertyAddress.CountyName[0]
          : '---',
        state: propertyAddress.StateCode
          ? propertyAddress.StateCode[0].toUpperCase()
          : '---',
        zipcode: propertyAddress.PostalCode
          ? propertyAddress.PostalCode[0]
          : '---',
        noUnits: propertyDetail.FinancedUnitCount
          ? parseInt(propertyDetail.FinancedUnitCount[0])
          : 1,
        occupancyType:
          propertyDetail.PropertyUsageType[0] === 'Investment'
            ? 3
            : propertyDetail.PropertyUsageType[0] === 'PrimaryResidence'
            ? 1
            : 2,
        purchasePrice: contractDetail
          ? contractDetail[0].SalesContractAmount[0]
          : '',
        appraisalValue: propertyDetail.PropertyEstimatedValueAmount
          ? propertyDetail.PropertyEstimatedValueAmount[0]
          : '',
        type:
          structureType === 'Condominium' || unitIdentifier
            ? 2
            : propertyDetail.PUDIndicator[0] === 'true'
            ? 3
            : propertyDetail.AttachmentType
            ? propertyDetail.AttachmentType[0] === 'Attached'
              ? 2
              : 1
            : 1,
      };
    } catch {
      loan.parseError = 'Error: Property Address Data Missing or Incomplete in XML File!!';
      return;
    }

    try {
      loan.purchasePrice = contractDetail
        ? contractDetail[0].SalesContractAmount[0]
        : 0;
      if (!loan.purchasePrice)
        loan.purchasePrice = propertyDetail.PropertyEstimatedValueAmount
          ? propertyDetail.PropertyEstimatedValueAmount[0]
          : '';
      loan.appraisalValue = propertyDetail.PropertyEstimatedValueAmount
        ? propertyDetail.PropertyEstimatedValueAmount[0]
        : '';
    } catch {
      loan.parseError = 'Error: Property Pricing Data Missing or Incomplete in XML File!!';
      return;
    }

    // III. Borrower
    var borrowerCount = 0;
    var partiesNode = null;

    // if parties node does not exist
    if (!xml.MESSAGE.DEAL_SETS[0].DEAL_SET[0].DEALS[0].DEAL[0].PARTIES || !xml.MESSAGE.DEAL_SETS[0].DEAL_SET[0].DEALS[0].DEAL[0].PARTIES[0] ||
      !xml.MESSAGE.DEAL_SETS[0].DEAL_SET[0].DEALS[0].DEAL[0].PARTIES[0].PARTY) {
      loan.parseError = 'Error: DU 3.4 File Missing Borrower(s) Information in XML File!';
      return;
    }
    partiesNode =
      xml.MESSAGE.DEAL_SETS[0].DEAL_SET[0].DEALS[0].DEAL[0].PARTIES[0].PARTY;

    for (var i = 0; i < partiesNode.length; i++) {
      if(!partiesNode[i].ROLES || !partiesNode[i].ROLES[0] || !partiesNode[i].ROLES[0].ROLE || !partiesNode[i].ROLES[0].ROLE[0] ||
        !partiesNode[i].ROLES[0].ROLE[0].ROLE_DETAIL || !partiesNode[i].ROLES[0].ROLE[0].ROLE_DETAIL[0] || 
        !partiesNode[i].ROLES[0].ROLE[0].ROLE_DETAIL[0].PartyRoleType || !partiesNode[i].ROLES[0].ROLE[0].ROLE_DETAIL[0].PartyRoleType[0]) {
        loan.parseError = 'Error: Parties <PartyRoleType> Node Missing in XML File!!';
        return;
      }
      if (
        partiesNode[i].ROLES[0].ROLE[0].ROLE_DETAIL[0].PartyRoleType[0] ===
        'Borrower'
      ) {
        
        if(!partiesNode[i].INDIVIDUAL || !partiesNode[i].INDIVIDUAL[0] || !partiesNode[i].INDIVIDUAL[0].CONTACT_POINTS) {
          // there can be "legal_entities" which are not borrowers, so if we can't find the individual node, continue.
          continue;
        }
        const contactNode = partiesNode[i].INDIVIDUAL[0].CONTACT_POINTS
          ? partiesNode[i].INDIVIDUAL[0].CONTACT_POINTS[0]
          : null;
        if(!partiesNode[i].ROLES || !partiesNode[i].ROLES[0] || !partiesNode[i].ROLES[0].ROLE || !partiesNode[i].ROLES[0].ROLE[0] ||
          !partiesNode[i].ROLES[0].ROLE[0].BORROWER || !partiesNode[i].ROLES[0].ROLE[0].BORROWER[0] || 
          !partiesNode[i].ROLES[0].ROLE[0].BORROWER[0].BORROWER_DETAIL || !partiesNode[i].ROLES[0].ROLE[0].BORROWER[0].BORROWER_DETAIL[0]) {
          loan.parseError = 'Error: <BORROWER_DETAIL> Node Missing in XML File!!';
          return;
        }
        const borrowerDetailNode =
          partiesNode[i].ROLES[0].ROLE[0].BORROWER[0].BORROWER_DETAIL[0];

        var brwSsn = null;
        var brwFirstName = null;
        var brwMiddleName = null;
        var brwLastName = null;
        var brwDoB = null;
        var brwPhone = null;

        try {
          brwSsn =
            partiesNode[i].TAXPAYER_IDENTIFIERS[0].TAXPAYER_IDENTIFIER[0]
              .TaxpayerIdentifierValue[0];
        } catch {
          loan.parseError = 'Error: Borrower Data Missing SSN!';
          return;
        }

        try {
          brwFirstName = partiesNode[i].INDIVIDUAL[0].NAME[0].FirstName[0];
          brwMiddleName = partiesNode[i].INDIVIDUAL[0].NAME[0].MiddleName
            ? partiesNode[i].INDIVIDUAL[0].NAME[0].MiddleName[0]
            : '';
          brwLastName = partiesNode[i].INDIVIDUAL[0].NAME[0].LastName[0];
        } catch {
          loan.parseError = 'Error: Borrower Name Missing or Incomplete!';
          return;
        }

        try {
          brwDoB = borrowerDetailNode.BorrowerBirthDate[0];
        } catch {
          loan.parseError = 'Error: Borrower Birth Date Missing!';
          return;
        }

        try {
          brwPhone = contactNode
            ? contactNode.CONTACT_POINT[0].CONTACT_POINT_TELEPHONE
              ? contactNode.CONTACT_POINT[0].CONTACT_POINT_TELEPHONE[0]
                  .ContactPointTelephoneValue[0]
              : contactNode.CONTACT_POINT.length > 1 &&
                contactNode.CONTACT_POINT[1].CONTACT_POINT_TELEPHONE
              ? contactNode.CONTACT_POINT[1].CONTACT_POINT_TELEPHONE[0]
                  .ContactPointTelephoneValue[0]
              : ''
            : '';
        } catch {
          loan.parseError = 'Error: Borrower Phone Number Missing or Invalid!';
          return;
        }

        loan.borrowers[borrowerCount] = {
          ssn: brwSsn,
          firstName: brwFirstName,
          middleName: brwMiddleName,
          lastName: brwLastName,
          dateOfBirth: brwDoB,
          phone: brwPhone,
        };

        // need a separate loop for the email, as it can be in multiple different indexes.
        try {
          for (var j = 0; j < contactNode.CONTACT_POINT.length; j++) {
            if (contactNode && contactNode.CONTACT_POINT[j].CONTACT_POINT_EMAIL)
              loan.borrowers[borrowerCount].email =
                contactNode.CONTACT_POINT[
                  j
                ].CONTACT_POINT_EMAIL[0].ContactPointEmailValue[0];
          }
        } catch {
          loan.parseError = 'Error: Borrower Email Missing or Invalid!';
          return;
        }
        borrowerCount++;
      }
    }

    // initialize county if we can
    fields.county = loan.property.county;

    // default fields
    fields.impound = 1;
    fields.documentType = 1;
    fields.compPlan = 1;
    fields.eDisclosure = 1;
    fields.ausOnly = 2;

    if(LoginState.orgType === 'Wholesale') {
      fields.processingFee = formatCurrency(LoginState.flatFee);
      fields.adminFee = formatCurrency(0);
    }

    const ltv = getLTV(loan).toString();
    try {
      fields.pmi = parseFloat(ltv.substring(ltv.length - 1)) >= 80 ? 2 : 1;
    } catch {
      fields.pmi = 1;
    }

    // initialize fields based on input
    fields.occupancyType = loan.property.occupancyType;
    fields.propertyType =
      loan.property.noUnits > 1
        ? loan.property.noUnits + 3
        : loan.property.type;
    fields.purchasePrice =
      loan.property.appraisalValue || loan.property.purchasePrice;

    switch (loan.purpose) {
      case 'PUR':
        fields.loanPurpose = 1;
        break;
      case 'R/T':
        fields.loanPurpose = 2;
        break;
      case 'C/O (Debt Consolidation)':
        fields.loanPurpose = 3;
        break;
      case 'C/O (Home Improvement)':
        fields.loanPurpose = 4;
        break;
      case 'C/O (Other)':
        fields.loanPurpose = 5;
        break;
      default:
        fields.loanPurpose = -1;
        break;
    }
    // loan product
    fields.product = loan.amortizationType === 5 ? 1 : 2;

    // pass in address and ssns
    var address = loan.property.streetAddress + " " + loan.property.zipcode;
    var ssns = loan.borrowers[0].ssn;
    for(var i=1; i<loan.borrowers.length; i++) {
      ssns += "$$$$$" + loan.borrowers[i].ssn;
    }
    checkForCancelled(address, ssns);
  };

  const fieldAndLtvChange = e => {
    handleFieldChange(e);

    if (e.target.name === 'purchasePrice') {
      loanFile.purchasePrice = e.target.value;
    }
  };

  const handlePurposeChange = e => {
    handleFieldChange(e);
    loanFile.purpose = e.target.value;
  };

  const handleDocTypeChange = e => {
    fields.product = 0;
    handleFieldChange(e);
  };

  // for initialization if coming back from a later step
  if (!isEmpty(loan) && fnmFile && resetClicked !== true) {
    setRestart(true); // to prevent infinite loop
    setLoan(loan);
    setFnmFile(fnmFile);

    var i;
    if (loan.documentType.toString() === '2') {
      for (i = 0; i < PRICING_OPTIONS.loanProductPortfolio.length; i++) {
        if (PRICING_OPTIONS.loanProductPortfolio[i].label === loan.product) {
          fields.product = PRICING_OPTIONS.loanProductPortfolio[i].value;
          break;
        }
      }
    } else {
      for (i = 0; i < PRICING_OPTIONS.loanProduct.length; i++) {
        if (PRICING_OPTIONS.loanProduct[i].label === loan.product) {
          fields.product = PRICING_OPTIONS.loanProduct[i].value;
          break;
        }
      }
    }
    fields.fico = loan.borrowers[0].fico;
    fields.dti = loan.borrowers[0].dti;
    fields.eDisclosure = loan.eDisclosure;
    fields.documentType = loan.documentType;
    fields.purchasePrice = loan.purchasePrice;
    fields.county = loan.county;
    fields.impound = loan.impound;
    fields.compPlan = loan.compPlan;
    fields.pmi = loan.pmi;
    fields.ausOnly = loan.ausOnly;

    fields.loanPurpose = loan.purpose;
    fields.occupancyType = loan.occupancyType;
    fields.propertyType = loan.propertyType;
    fields.originationFee = loan.originationFee;
    fields.adminFee = loan.adminFee;
    fields.processingFee = (LoginState.orgType === 'Wholesale') ? LoginState.flatFee : loan.processingFee;
  }

  if (!isEmpty(loanFile) && fnmFileRead) {
    const { borrowers, property, lienPosition, amount } = loanFile;

    // if parse error, display error message
    if (loanFile.parseError) {
      return (
        <div className="col-12">
          <div className="row p-0">
            <div className="text-center">
              <h5 style={{ whiteSpace: 'pre' }}>{loanFile.parseError}</h5>
            </div>
            <div className="col-12">
              <div style={{ textAlign: 'center' }}>
                <button
                  className="btn btn-primary text-white ms-3"
                  onClick={restartImport}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }

    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return (
      <div className="row">
        <div className="col-12 col-lg-4">
          <div className="row">
            <div className="col-12 mb-2 mb-xl-4">
              <h3 className="border-bottom border-bottom-2 border-gray text-capitalize mb-1 mt-3">
                Borrower Details
              </h3>
              <div className="row p-0">
                <div className="col-12">
                  <ViewElement
                    label="Borrower Name"
                    value={`${borrowers[0].firstName} ${borrowers[0].lastName}`}
                  />
                </div>
                <div className="col-12 col-xl-6">
                  <FormInput
                    type="number"
                    name="fico"
                    label="FICO"
                    error={errors.fico}
                    value={fields.fico}
                    onChange={handleFieldChange}
                    tabIndex={1}
                  />
                  <FormSelect
                    name="eDisclosure"
                    label="e-Disclosure"
                    error={errors.eDisclosure}
                    value={fields.eDisclosure}
                    onChange={handleFieldChange}
                    tabIndex={3}
                    options={PRICING_OPTIONS.eDisclosure}
                  />
                </div>
                <div className="col-12 col-xl-6">
                  <FormInput
                    type="number"
                    name="dti"
                    label="DTI"
                    error={errors.dti}
                    value={fields.dti}
                    onChange={handleFieldChange}
                    tabIndex={2}
                  />
                  <FormSelect
                    name="ausOnly"
                    label="AUS Only"
                    error={errors.ausOnly}
                    value={fields.ausOnly}
                    onChange={handleFieldChange}
                    tabIndex={4}
                    options={PRICING_OPTIONS.ausOnly}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 mb-2 mb-xl-4">
              <h3 className="border-bottom border-bottom-2 border-gray text-capitalize mb-1 mt-3">
                Property Details
              </h3>
              <div className="row">
                <div className="col-12 col-xl-6">
                  <ViewElement
                    label="Appraisal Value"
                    value={
                      property.appraisalValue
                        ? formatter.format(property.appraisalValue)
                        : '---'
                    }
                  />

                  <FormSelect
                    name="propertyType"
                    label="Property Type"
                    error={errors.propertyType}
                    value={fields.propertyType}
                    onChange={handleFieldChange}
                    tabIndex={6}
                    options={PRICING_OPTIONS.propertyType}
                  />

                  <ViewElement
                    type="text"
                    label="County"
                    error={errors.county}
                    value={fields.county}
                    tabIndex={7}
                  />

                </div>
                <div className="col-12 col-xl-6">
                  {fields.loanPurpose === 1 || fields.loanPurpose === '1' ? (
                    <FormInputCurrency
                      type="number"
                      name="purchasePrice"
                      label="Purchase Price"
                      error={errors.purchasePrice}
                      value={fields.purchasePrice}
                      onChange={fieldAndLtvChange}
                      tabIndex={5}
                    />
                  ) : (
                    <FormInputCurrency
                      type="number"
                      name="purchasePrice"
                      label="Estimated Value"
                      error={errors.purchasePrice}
                      value={fields.purchasePrice}
                      onChange={fieldAndLtvChange}
                      tabIndex={5}
                    />
                  )}
                </div>
                <div className="col-12">
                  <ViewElement
                    label="Property Address"
                    textArea
                    value={
                      property.streetAddress === '---'
                        ? 'TBD'
                        : `${property.streetAddress} ${property.city}, ${property.state} ${property.zipcode}`
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-4 mb-2 mb-xl-4">
          <h3 className="border-bottom border-bottom-2 border-gray text-capitalize mb-1 mt-3">
            Loan Details
          </h3>
          <div className="row">
            <div className="col-12 col-xl-6">
              {lienPosition !== 2 ? (
                <ViewElement
                  label="1st Loan Amount"
                  value={formatter.format(amount)}
                />
              ) : (
                <ViewElement />
              )}
              <ViewElement label="LTV" value={getLTV(loanFile)} />
              <ViewElement
                label="Lien Position"
                value={DROPDOWN_VALUES.lienPosition[lienPosition]}
              />

              <FormSelect
                name="occupancyType"
                label="Occupancy Type"
                error={errors.occupancyType}
                value={fields.occupancyType}
                onChange={handleFieldChange}
                tabIndex={9}
                options={PRICING_OPTIONS.occupancyType}
              />

              <FormSelect
                name="product"
                label="Loan Product"
                error={errors.product}
                value={fields.product}
                onChange={handleFieldChange}
                tabIndex={11}
                options={
                  fields.documentType.toString() === '2'
                    ? PRICING_OPTIONS.loanProductPortfolio
                    : PRICING_OPTIONS.loanProduct
                }
              />
              <FormSelect
                name="impound"
                label="Escrow Account"
                error={errors.impound}
                value={fields.impound}
                onChange={handleFieldChange}
                tabIndex={12}
                options={PRICING_OPTIONS.impound}
              />
              <ViewElement label="Business Channel" value={LoginState.orgType??'Retail'} />
            </div>
            <div className="col-12 col-xl-6">
              {lienPosition > 1 ? (
                <ViewElement label="2nd Loan Amount" value={0} />
              ) : (
                <ViewElement />
              )}
              <ViewElement label="CLTV" value={getCLTV(loanFile)} />
              <FormSelect
                name="loanPurpose"
                label="Loan Purpose"
                error={errors.loanPurpose}
                value={fields.loanPurpose}
                onChange={handlePurposeChange}
                tabIndex={8}
                options={PRICING_OPTIONS.loanPurpose}
              />
              <FormSelect
                name="documentType"
                label="Document Type"
                error={errors.documentType}
                value={fields.documentType}
                onChange={handleDocTypeChange}
                tabIndex={10}
                options={PRICING_OPTIONS.documentType}
              />
              <ViewElement />
              <FormSelect
                name="pmi"
                label="PMI"
                error={errors.pmi}
                value={fields.pmi}
                onChange={handleFieldChange}
                tabIndex={13}
                options={PRICING_OPTIONS.pmi}
              />
              {(LoginState.orgType === 'Wholesale') && (
                <FormSelect
                  name="compPlan"
                  label="Comp. Plan"
                  error={errors.compPlan}
                  value={fields.compPlan}
                  onChange={handleFieldChange}
                  tabIndex={15}
                  options={COMP_VALUES.options}
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-4 mb-2 mb-xl-4">
          <h3 className="border-bottom border-bottom-2 border-gray text-capitalize mb-1 mt-3">
            Fees
          </h3>
          <div className="row">
            <div className="col-12 col-xl-6">
              <FormInputPercentage
                label="Origination Fee %"
                type="number"
                name="originationFee"
                error={errors.originationFee}
                value={fields.originationFee}
                onChange={handleFieldChange}
                tabIndex={16}
              />
              {(LoginState.orgType === 'Wholesale') ? (
                <ViewElement />
              ) : (
                <FormInputCurrency
                  label="Admin Fee"
                  type="number"
                  name="adminFee"
                  error={errors.adminFee}
                  value={fields.adminFee}
                  onChange={handleFieldChange}
                  tabIndex={18}
                />
              )}
            </div>

            <div className="col-12 col-xl-6">
              {(LoginState.orgType === 'Wholesale') ? (
                <ViewElement label="Processing Fee" value={formatCurrency(LoginState.flatFee)} />
              ) : (
                <FormInputCurrency
                  label="Processing Fee"
                  type="number"
                  name="processingFee"
                  error={errors.processingFee}
                  value={fields.processingFee}
                  onChange={handleFieldChange}
                  tabIndex={17}
                />
              )}
            </div>
          </div>
        </div>
        {cancelledWarning && (
          <div className="col-12 text-end" style={{color:'red', fontSize:'14px', marginBottom:'20px'}}>
            <b>Warning:</b> {cancelledWarning}
            <br/>
            Please Contact a Representative at MTGRL To Continue Registration For This File
          </div>
        )}
        <div className="col-12 text-end">
          <button className="btn btn-light" onClick={restartImport}>
            Cancel
          </button>
          <button
            className="btn btn-primary text-white ms-3"
            onClick={validate}
            tabIndex={20}
            disabled={cancelledWarning && !LoginState.userRole.toLowerCase().includes('admin')}
          >
            CONTINUE TO STEP 2
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="text-center">
        <h5>
          Let's begin by importing a DU 3.4 File (file format should be .xml)
        </h5>
        <Dropzone
          maxFiles="1"
          accept=".xml"
          onChangeStatus={parseFnmFile}
          inputContent={() => <Placeholder />}
          canCancel={false}
          styles={{
            dropzone: {
              border: '2px dashed lightgray',
              height: '200px',
              overflow: 'hidden',
              borderRadius: '0.25rem',
            },
          }}
        />
      </div>
    );
  }
};

export default FNMImport;
