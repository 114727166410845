import {
  Body,
  Verify,
  Task,
  Email,
  AutoCheck,
  SignOff,
  FindLabelFromName,
  TableZero,
} from './FileDetailsHelpers';
import LoginState from '../LoginState';
import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import { PRICING_OPTIONS, STATUS_COLORS } from '../../helpers/constants';
import { todaysDateYYYY, getPropertyType, isAdmin } from '../../helpers/utils';
import Toast from '../Toast';
import ModalEmail from './ModalEmail';
import ModalEmailLog from './ModalEmailLog';
import ModalConfirm from '../ModalConfirm';
import ChecklistCard from './ChecklistCard';

export default class UnderwritingChecklist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFile: this.props.id,
      fileData: [],
      ltvFico: [],
      floodInsRequired: false,
      zoningTest: false,
      incomeTest: false,
      isLoading: true,
      errorMessage: '',
      successMessage: '',
      checkList: {
        changeInUnderwriting: { checked: false },
        borrower: { checked: false },
        foreignNational: { checked: false },
        address: { checked: false },
        craQual: { checked: false },
        loanPurpose: { checked: false },
        occupancyType: { checked: false },
        propertyType: { checked: false },
        housingHistory: { checked: false },
        unitOver4: { checked: false },
        ltv: { checked: false },
        pnlPrep: { checked: false },
        incomeType: { checked: false },
        dti: { checked: false },
        creditReport: { checked: false },
        originalCreditor: { checked: false },
        fico: { checked: false },
        mers: { checked: false },
        flood: { checked: false },
        changeApproved: { checked: false },
        conditionalApproval: { checked: false },
        complete: { checked: false },
      },
      showEmailTemplate: false,
      emailTemplate: [],
      conditionalTemplate: [],
      conditionalEmailLog: [],
    };
    this.getUnderwritingData();
  }

  async getUnderwritingData() {
    var response = axios.get('/getloansummary', {
      params: { pFileID: this.state.activeFile },
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;

    var intPropType = parseInt(resultData.propertyType);
    if (parseInt(resultData.numberOfUnits) > 1) {
      resultData.propertyType = resultData.numberOfUnits + ' Unit';
    } else {
      resultData.propertyType = getPropertyType(intPropType);
    }

    switch (resultData.loanPurpose) {
      case 'PUR':
        resultData.loanPurpose = 'Purchase';
        break;
      case 'R/T':
        resultData.loanPurpose = 'R/T Refinance';
        break;
      default:
        resultData.loanPurpose = 'C/O Refinance';
        break;
    }

    try {
      resultData.occupancyType =
        PRICING_OPTIONS.occupancyType[
          parseInt(resultData.occupancyType) - 1
        ].label;
    } catch {
      resultData.occupancyType = '? OCCUPANCY TYPE ?';
    }

    response = axios.get('/getmaxltvminfico', {
      params: {
        pProgramCode: resultData.programCode,
        pUnitType: resultData.propertyType,
        pOccupancyType: resultData.occupancyType,
        pLoanPurpose: resultData.loanPurpose,
        pForeignNational: '0',
        pLoanAmount: resultData.firstLoan,
        pLTV: resultData.ltvCltvHcltv.split('%')[0],
        pFico: resultData.creditScore,
        pClosedDate: resultData.closedDate,
        pIncomeVerification: resultData.incomeVerification
      },
    });
    var maxLtvMinFico = (await response).data;

    response = axios.get('/getunderwritingfields', {
      params: {
        pFileID: this.state.activeFile,
      },
    });

    var underwritingFields = (await response).data;
    var checklistData = this.state.checkList;
    var statelist = {
      fileData: resultData,
      selfEmployed: underwritingFields.selfEmployed !== '0',
      creditReports: underwritingFields.creditReports,
      floodInsRequired: underwritingFields.floodInsurance === '1',
      originalFloodInsRequired: underwritingFields.floodInsurance === '1',
      craQualified: underwritingFields.craQualified === '1',
      zoningTest: underwritingFields.zoningTest === '1',
      incomeTest: underwritingFields.incomeTest === '1',
      ocMtg: underwritingFields.originalCreditorMtg,
      missedPayments: underwritingFields.missedPayments,
      moreThan4Units: underwritingFields.moreThan4Units,
      pnlPreparer: underwritingFields.pnlPreparer,
      incomeVerify: underwritingFields.incomeVerify,
      mersActiveProperties: underwritingFields.mersActiveProperties,
      ltvFico: maxLtvMinFico,
      isForeignNat: underwritingFields.foreignNational,
      rateLocked: underwritingFields.rateLocked !== '0',
      conditionalTemplate: underwritingFields.conditionalTemplate,
      conditionalEmailLog: underwritingFields.conditionalEmailLog,
      isLoading: false,
    };
    for (var j = 0; j < underwritingFields.creditReports.length; j++) {
      statelist['crDate' + j] = underwritingFields.creditReports[j].pullDate;
      statelist['originalCrDate' + j] =
        underwritingFields.creditReports[j].pullDate;
      checklistData['cr' + underwritingFields.creditReports[j].docID] = {
        checked: false,
      };
    }

    // get checklist data
    response = axios.get('/getchecklistdata', {
      params: { pFileID: this.state.activeFile, pChecklistType: 'UWR' },
    });

    var checkData = (await response).data;

    for (var k = 0; k < checkData.length; k++) {
      checklistData[checkData[k].fieldName] = {
        checked: checkData[k].isChecked === 'True',
        timestamp: checkData[k].timestamp,
        userName: checkData[k].userName,
      };
    }
    statelist['checkList'] = checklistData;
    this.setState(statelist);
  }

  async setChecklistField(fieldName, checked) {
    axios.get('/setchecklistfield', {
      params: {
        pFileID: this.state.activeFile,
        pFieldName: 'UWR-' + fieldName,
        pIsChecked: checked,
        pUserName: LoginState.userName,
      },
    });
  }

  captureInput = e => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + 'Updated']: false,
    });
  };

  _toggleCheckbox = ({ target: { name, checked } }) => {
    if (checked) {
      this.setState({ confirmTitle: name, showConfirmModal: true });
    } else if (isAdmin()) {
      this.setState({ confirmTitle: name, showClearModal: true });
    }
  };

  setCheckbox(checked) {
    const name = this.state.confirmTitle;
    const { checkList } = this.state;
    const fieldItem = checkList[name];
    fieldItem.checked = checked;
    fieldItem.timestamp = new Date()
      .toLocaleString()
      .split(/\D/)
      .slice(0, 3)
      .map(num => num.padStart(2, '0'))
      .join('/');
    fieldItem.userName = LoginState.userName;
    this.setState({
      checkList: checkList,
      showConfirmModal: false,
      showClearModal: false,
    });
    this.setChecklistField(name, checked);
    if (!checked) {
      checkList.complete.checked = false;
      this.setChecklistField('complete', false);
    }
  }

  // fieldsToUpdate [fieldName, docID, dataValue, checkboxName, stateField]
  async updateDynamicDataField(fieldsToUpdate) {
    for (var i = 0; i < fieldsToUpdate.length; i++) {
      var response = axios.get('/updatedynamicfield', {
        params: {
          pFileID: this.state.activeFile,
          pList: 'UWR',
          pFieldName: fieldsToUpdate[i][0], // fieldName
          pDocID: fieldsToUpdate[i][1], // docID
          pDataValue: fieldsToUpdate[i][2], // dataValue
        },
      });
      var resultData = (await response).data;
      if (resultData === 'Success') {
        if (fieldsToUpdate[i][4]) {
          this.setState({
            ['original' +
            fieldsToUpdate[i][4].substring(0, 1).toUpperCase() +
            fieldsToUpdate[i][4].substring(1)]:
              this.state[fieldsToUpdate[i][4]],
            [fieldsToUpdate[i][4] + 'Updated']: true,
          });
        }
        // if we cleared / empty string
        if (!fieldsToUpdate[i][2] && fieldsToUpdate[i][3]) {
          var checkList = this.state.checkList;
          checkList[fieldsToUpdate[i][3]].checked = false;
          this.setState({ checkList: checkList });
          this.setChecklistField(fieldsToUpdate[i][3], false);
        }
      } else {
        this.setState({ errorMessage: 'Error: Database Not Updated!' });
      }
    }
  }

  async downloadLoanFile(docID) {
    this.setState({ successMessage: 'Downloading File\nPlease Wait!' });
    const response = axios.get('/DownloadLoanFile', {
      params: {
        pFileID: this.state.activeFile,
        pDocumentID: docID,
      },
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;

    // if we couldn't get a file back, abort
    if (!resultData) {
      return;
    }

    var binary = atob(resultData.documentData);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    var file = new Blob([new Uint8Array(array)]);

    // this code below will download the file we selected
    const url = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = url;
    a.download = resultData.documentName + '.' + resultData.documentExension;
    a.click();
    URL.revokeObjectURL(url);

    this.setState({ successMessage: '' });
  }

  async setLoanStatus(status) {
    this.setState({ changingState: status });
    var response = axios.get('/updateLoanStatus', {
      params: {
        pFileID: this.state.activeFile,
        pLoanStatus: status,
        pLoanPurpose: this.state.fileData.loanPurpose,
      },
    });
    var resultData = (await response).data;
    if (resultData === 'Success') {
      var file = this.state.fileData;
      file.loanStatus = status;
      switch (status) {
        case 'In Underwriting':
          file.inUnderwritingDate = todaysDateYYYY();
          break;
        case 'Approved':
          file.approvedDate = todaysDateYYYY();
          break;
        default:
          break;
      }
      this.setState({ fileData: file, changingState: false });
    } else {
      this.setState({ errorMessage: resultData, changingState: false });
    }
  }
  
  delay(milliseconds) {
    return new Promise(resolve => {
      setTimeout(resolve, milliseconds);
    });
  }

  async allChecked() {
    var visibleCount = 0;
    var checkedCount = 0;
    var checklistData = this.state.checkList;
    for (var prop in checklistData) {
      if (prop === 'complete') continue;
      if (prop === 'craQual' && !this.state.fileData.programCode.endsWith('C')) continue;
      if (prop === 'originalCreditor' && this.state.fileData.loanPurpose.includes('Purchase')) continue;
      if (prop === 'unitOver4' && !this.state.fileData.programCode.includes('MTGP1')) continue;
      if (prop === 'housingHistory' && !this.state.fileData.programCode.includes('MTGP2')) continue;
      if (prop === 'foreignNational' && !this.state.fileData.programCode.includes('MTGP1')) continue;
      if (prop === 'pnlPrep' && (!this.state.fileData.programCode.includes('MTGP1') || !this.state.selfEmployed)) continue;
      if (prop === 'incomeType' && (!this.state.fileData.programCode.includes('MTGP2') || !this.state.selfEmployed)) continue;
      if (prop.startsWith('cr') && !prop.startsWith('creditReport')) {
        var reportExists = false;
        for (var i = 0; i < this.state.creditReports.length; i++) {
          if (prop === 'cr' + this.state.creditReports[i].docID) {
            reportExists = true;
            break;
          }
        }
        // skip crs that no longer exist
        if (!reportExists) continue;
      }
      visibleCount++;
      if (prop === 'changeInUnderwriting') {
        if (this.state.fileData.inUnderwritingDate !== '---') {
          checkedCount++;
        }
        continue;
      }
      if (prop === 'changeApproved') {
        if (this.state.fileData.approvedDate !== '---') {
          checkedCount++;
        }
        continue;
      }
      if (
        prop === 'ltv' &&
        parseFloat(this.state.ltvFico.maxLtv) >=
          parseFloat(this.state.fileData.ltvCltvHcltv.split('%')[0])
      ) {
        checkedCount++;
        continue;
      }
      if (
        Object.prototype.hasOwnProperty.call(checklistData, prop) &&
        checklistData[prop].checked
      )
        checkedCount++;
    }
    var completePercent = this.state.fileData.completePercent
      ?.split('UWR')[1]
      .split('(')[1]
      .split(')')[0];
    if (
      completePercent.toString() !==
      Math.round((checkedCount / visibleCount) * 100).toString()
    ) {
      this.state.fileData.completePercent =
        this.state.fileData.completePercent?.split('UWR')[0] +
        'UWR (' +
        Math.round((checkedCount / visibleCount) * 100).toString() +
        ')' +
        this.state.fileData.completePercent
          ?.split('UWR')[1]
          .substring(
            this.state.fileData.completePercent?.split('UWR')[1].indexOf(')') +
              1
          );
      this.props.updatePercent(
        'UWR',
        Math.round((checkedCount / visibleCount) * 100).toString()
      );
    }
    if (checkedCount < visibleCount || this.state.creditReports.length === 0) {
      if(this.state.checkList.complete.checked == true) {
        this.state.checkList.complete.checked = false;
        this.setChecklistField('complete', false);
        await this.delay(500);
        this.props.updatePercent('UWR', Math.round((checkedCount / visibleCount) * 100).toString());
      }
      return false;
    }
    return true;
  }

  markComplete() {
    var reportList = '';
    for (var i = 0; i < this.state.creditReports.length; i++) {
      reportList =
        reportList +
        (i !== 0 ? ',' : '') +
        this.state.creditReports[i].docID +
        ',' +
        this.getExpirationDate(
          'Credit',
          this.state['crDate' + this.state.creditReports[i].index]
        );
    }

    axios.get('/UnderwritingChecklistComplete', {
      params: { pFileID: this.state.activeFile, pCreditReports: reportList },
    });
    this.setChecklistField('complete', true);
    var cl = this.state.checkList;
    cl.complete.checked = true;
    this.setState({ checkList: cl });
    this.props.updatePercent('UWR', 'complete');
  }

  checkAgainstClosingDate(reportType, dateValue) {
    var daysToAdd =
      !this.state.fileData.programCode.includes('MTGP') &&
      reportType === 'Credit'
        ? 120
        : 90;

    // if null or empty, skip
    if (!dateValue || !this.state.fileData.signingDate) {
      return;
    }

    var expDate = moment(dateValue, 'YYYY-MM-DD').add(daysToAdd, 'day');
    var funding = moment(this.state.fileData.signingDate, 'MM/DD/YYYY');
    if (expDate.isBefore(funding))
      return (
        'Report Will Expire (' +
        expDate.format('MM/DD/YY') +
        ')\nBefore Closing Date (' +
        funding.format('MM/DD/YY') +
        ')'
      );
    else return null;
  }

  getExpirationDate(reportType, dateValue) {
    try {
      var daysToAdd =
        !this.state.fileData.programCode.includes('MTGP') &&
        reportType === 'Credit'
          ? 120
          : 90;
      if (!dateValue || !this.state.fileData.signingDate) {
        return;
      }
      var expDate = moment(dateValue, 'YYYY-MM-DD').add(daysToAdd, 'day');

      // check for expiration or almost expired
      var today14 = moment().add(14, 'day');
      var extra = '';
      if (expDate < moment().add(-1, 'day')) extra = ' (Expired!)';
      else if (expDate < today14) extra = ' (Expiring Soon!)';

      return expDate.format('MM/DD/YY') + extra;
    } catch {
      return null;
    }
  }

  checkAgainstFundingDate(dateValue) {
    // if null or empty, skip
    if (!dateValue || !this.state.fileData.fundingDate) {
      return;
    }

    var stDate = moment(dateValue, 'YYYY-MM-DD');
    var funding = moment(this.state.fileData.fundingDate, 'MM/DD/YYYY');
    if (stDate.isAfter(funding))
      return (
        'Insurance Must Start (' +
        stDate.format('MM/DD/YY') +
        ')\nOn Or Before Funding Date (' +
        funding.format('MM/DD/YY') +
        ')'
      );
    else return null;
  }

  dtiUnderLimits() {
    const { ltvFico } = this.state;
    try {
      var split = this.state.fileData.dti.split('/');
      var front = parseFloat(split[0].trim().replace('%', ''));
      var back = parseFloat(split[1].trim().replace('%', ''));
      return front <= ltvFico.maxFrontDTI && back <= ltvFico.maxBackDTI;
    } catch {
      return false;
    }
  }

  async ClearAll() {
    await axios.get('/ClearCheckboxes', {
      params: { pFileID: this.state.activeFile, checklist: 'UWR' },
    });
    this.setState({ showClearAllModal: false });
    this.getUnderwritingData();
  }

  render() {
    const { isLoading, fileData, checkList, ltvFico } = this.state;
    if (isLoading) {
      return (
        <React.Fragment>
          <h1 className="h5">Underwriting Checklist</h1>
          <Body>
            <div style={{ width: '100%', textAlign: 'center' }}>
              <img
                src="../images/spinner.gif"
                alt=""
                height="64px"
                width="64px"
              />
            </div>
          </Body>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <h1 className="h5">
          Underwriting Checklist
          {isAdmin() && (
            <button className="btn btn-outline-secondary"
              style={{
                fontSize: '12px',
                height: '24px',
                padding: '2px',
                width: '70px',
                marginLeft: '15px',
                pointerEvents: 'auto',
                position: 'relative',
                top: '-2px'
              }}
              onClick={() => this.setState({showClearAllModal: true}) }
            > 
              Clear All
            </button>
          )}
        </h1>
        <div>
          <h6>
            {fileData.programCode} + {fileData.loanPurpose} +{' '}
            {fileData.occupancyType} + {fileData.propertyType}
          </h6>
          <p className="text-secondary">
            (Please Check All Information Below For Spelling and Accuracy)
          </p>

          {/* Change Status (In Underwriting) */}
          <ChecklistCard
            checked={this.state.fileData.inUnderwritingDate !== '---'}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="changeInUnderwriting"
                  name="changeInUnderwriting"
                  disabled={true}
                  checked={this.state.fileData.inUnderwritingDate !== '---'}
                />
                <label
                  className="form-check-label"
                  htmlFor="changeInUnderwriting"
                >
                  In Underwriting
                </label>
              </div>
            }
            body={
              <div>
                <Verify tip="Change Loan Status to 'In Underwriting'" />
                {this.state.fileData.inUnderwritingDate !== '---' ? (
                  <React.Fragment>
                    <span
                      className={`fw-normal badge border rounded-pill`}
                      style={{
                        marginRight: '8px',
                        color: 'black',
                        backgroundColor: STATUS_COLORS['in underwriting'],
                      }}
                    >
                      In Underwriting
                    </span>
                    {this.state.fileData.inUnderwritingDate}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <span
                      className={`fw-normal badge border rounded-pill`}
                      style={{
                        marginRight: '8px',
                        color: [
                          'clear to close',
                          'clear to fund',
                          'in post-funding',
                          'on hold',
                        ].includes(this.state.fileData.loanStatus.toLowerCase())
                          ? 'white'
                          : 'black',
                        backgroundColor:
                          STATUS_COLORS[
                            this.state.fileData.loanStatus.toLowerCase()
                          ],
                      }}
                    >
                      {this.state.fileData.loanStatus}
                    </span>
                    <button
                      className="btn btn-sm btn-outline-primary ms-2"
                      style={{ width: '65px' }}
                      disabled={
                        this.state.changingState ||
                        this.state.fileData.inUnderwritingDate !== '---'
                      }
                      onClick={() => this.setLoanStatus('In Underwriting')}
                    >
                      {this.state.changingState === 'In Underwriting'
                        ? '...'
                        : 'Change'}
                    </button>
                  </React.Fragment>
                )}
              </div>
            }
            alert={
              <AutoCheck
                passed={this.state.fileData.inUnderwritingDate !== '---'}
                tip="In Underwriting Date Not Found"
              />
            }
          />

          {/* Borrower */}
          <ChecklistCard
            checked={checkList.borrower.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="borrower"
                  id="borrower"
                  checked={checkList.borrower.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="borrower">
                  Borrower(s)
                </label>
              </div>
            }
            body={
              <div>
                <Verify tip="Spelling Check" />
                {fileData.allBorrowers || '--'}
              </div>
            }
            alert={
              checkList.borrower.checked && (
                <SignOff
                  user={checkList.borrower.userName}
                  date={checkList.borrower.timestamp}
                />
              )
            }
          />

          {/* Foreign National */}
          {fileData.programCode.includes('MTGP1') && (
          <ChecklistCard
            checked={checkList.foreignNational.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="foreignNational"
                  name="foreignNational"
                  disabled={!this.state.isForeignNat}
                  checked={checkList.foreignNational.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="foreignNational">
                  Foreign National
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <Verify tip={'For MTGP1 Programs Only'} /> Is Borrower a Foreign National
                </div>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="isForeignNat1"
                      name="isForeignNat"
                      checked={this.state.isForeignNat === '1'}
                      disabled={checkList.foreignNational.checked}
                      onChange={() => void 0}
                      onClick={() => {
                        this.setState({ isForeignNat: '1' });
                        this.updateDynamicDataField([
                          ['IsForeignNational', '', '1', '', ''],
                        ]);
                      }}
                    />
                    <label htmlFor="isForeignNat1" className="form-check-label">
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="isForeignNat2"
                      name="isForeignNat"
                      checked={this.state.isForeignNat === '0'}
                      disabled={checkList.foreignNational.checked}
                      onChange={() => void 0}
                      onClick={() => {
                        this.setState({ isForeignNat: '0' });
                        this.updateDynamicDataField([
                          ['IsForeignNational', '', '0', '', ''],
                        ]);
                      }}
                    />
                    <label htmlFor="isForeignNat2" className="form-check-label">
                      No
                    </label>
                  </div>
                </div>
              </div>
            }
            alert={
              checkList.foreignNational.checked && (
                <SignOff
                  user={checkList.foreignNational.userName}
                  date={checkList.foreignNational.timestamp}
                />
              )
            }
          />
          )}

          {/* Address */}
          <ChecklistCard
            checked={checkList.address.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="address"
                  id="address"
                  checked={checkList.address.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="address">
                  Address
                </label>
              </div>
            }
            body={
              <div className="d-flex align-items-center">
                <Verify tip="Spelling Check and Google Search" />
                <a
                  target="_blank"
                  href={
                    'https://www.google.com/search?q=' +
                    (
                      fileData.street.replaceAll(' ', '+') +
                      '+' +
                      fileData.city.replaceAll(' ', '+') +
                      ',+' +
                      fileData.state.replaceAll(' ', '+') +
                      '+' +
                      fileData.zip
                    ).replaceAll('&', '%26')
                  }
                  rel="noopener noreferrer"
                >
                  <TableZero>
                    <tbody>
                      <tr>
                        <td>{fileData.street}</td>
                      </tr>
                      <tr>
                        <td>
                          {fileData.city}, {fileData.state} {fileData.zip}
                        </td>
                      </tr>
                    </tbody>
                  </TableZero>
                </a>
              </div>
            }
            alert={
              checkList.address.checked && (
                <SignOff
                  user={checkList.address.userName}
                  date={checkList.address.timestamp}
                />
              )
            }
          />

          {/* CRA Qualification */}
          {fileData.programCode.endsWith('C') && (
            <ChecklistCard
              checked={checkList.craQual.checked}
              label={
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="craQual"
                    id="craQual"
                    disabled={
                      this.state.craQualified &&
                      !this.state.zoningTest &&
                      !this.state.incomeTest
                    }
                    checked={checkList.craQual.checked}
                    onChange={this._toggleCheckbox}
                  />
                  <label className="form-check-label" htmlFor="craQual">
                    CRA Qualification
                  </label>
                </div>
              }
              body={
                <div className="vstack gap-3">
                  <div className="d-flex">
                    <Verify tip="Check if Qualified" />
                    <div
                      className="form-check form-check-inline"
                      style={{ marginLeft: '3px' }}
                    >
                      <input
                        type="radio"
                        id="cra1"
                        name="cra"
                        disabled={checkList.craQual.checked}
                        className="form-check-input"
                        checked={this.state.craQualified}
                        onChange={() => void 0}
                        onClick={() => {
                          if (!this.state.craQualified) {
                            this.setState({ craQualified: true });
                            this.updateDynamicDataField([
                              ['CRA Qual', '', '1', '', ''],
                            ]);
                            checkList.craQual.checked = false;
                            this.setChecklistField('craQual', false);
                          }
                        }}
                      />
                      <label className="form-check-label" htmlFor="cra1">
                        Qualified
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        type="radio"
                        id="cra2"
                        name="cra"
                        className="form-check-input"
                        checked={!this.state.craQualified}
                        disabled={checkList.craQual.checked}
                        onChange={() => void 0}
                        onClick={() => {
                          if (this.state.craQualified) {
                            this.setState({ craQualified: false });
                            this.updateDynamicDataField([
                              ['CRA Qual', '', '0', '', ''],
                            ]);
                            checkList.craQual.checked = false;
                            this.setChecklistField('craQual', false);
                          }
                        }}
                      />
                      <label className="form-check-label" htmlFor="cra2">
                        Not Qualified
                      </label>
                    </div>
                  </div>
                  {this.state.craQualified && (
                    <div>
                      <TableZero>
                        <tbody>
                          <tr>
                            <td>
                              <Verify tip="Qualification Type" />
                            </td>
                            <td>
                              {/* Passed Zoning Test */}
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="zoningTest"
                                id="zoningTest"
                                disabled={checkList.craQual.checked}
                                checked={this.state.zoningTest}
                                onChange={() => void 0}
                                onClick={() => {
                                  this.updateDynamicDataField([
                                    [
                                      'Zoning Test',
                                      '',
                                      this.state.zoningTest ? '0' : '1',
                                      '',
                                      '',
                                    ],
                                  ]);
                                  this.setState({
                                    zoningTest: this.state.zoningTest
                                      ? false
                                      : true,
                                  });
                                  checkList.craQual.checked = false;
                                  this.setChecklistField('craQual', false);
                                }}
                              />
                              <label
                                className="form-check-label"
                                style={{ marginLeft: '8px' }}
                                htmlFor="zoningTest"
                              >
                                Passed Zoning Test (Moderate/Low)
                              </label>
                              <br />
                              {/* Passed Income Test */}
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="incomeTest"
                                id="incomeTest"
                                disabled={checkList.craQual.checked}
                                checked={this.state.incomeTest}
                                onChange={() => void 0}
                                onClick={() => {
                                  this.updateDynamicDataField([
                                    [
                                      'Income Test',
                                      '',
                                      this.state.incomeTest ? '0' : '1',
                                      '',
                                      '',
                                    ],
                                  ]);
                                  this.setState({
                                    incomeTest: this.state.incomeTest
                                      ? false
                                      : true,
                                  });
                                  checkList.craQual.checked = false;
                                  this.setChecklistField('craQual', false);
                                }}
                              />
                              <label
                                className="form-check-label"
                                style={{ marginLeft: '8px' }}
                                htmlFor="incomeTest"
                              >
                                Passed Income Test (Less Than 80%)
                              </label>
                            </td>
                          </tr>
                        </tbody>
                      </TableZero>
                    </div>
                  )}
                </div>
              }
              alert={
                checkList.craQual.checked && (
                  <SignOff
                    user={checkList.craQual.userName}
                    date={checkList.craQual.timestamp}
                  />
                )
              }
            />
          )}

          {/* Loan Purpose */}
          <ChecklistCard
            checked={checkList.loanPurpose.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="loanPurpose"
                  id="loanPurpose"
                  checked={checkList.loanPurpose.checked}
                  disabled={
                    fileData.programCode.endsWith('C') &&
                    fileData.loanPurpose.includes('C/O')
                  }
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="loanPurpose">
                  Loan Purpose
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <Verify />
                  {fileData.loanPurpose}
                </div>
                {fileData.loanPurpose.includes('C/O') && (
                  <div>
                    <Verify tip="6 Months Seasoning Required for C/O Refinance" />
                    6 Months Seasoning
                  </div>
                )}
              </div>
            }
            alert={
              fileData.programCode.endsWith('C') &&
              fileData.loanPurpose.includes('C/O') ? (
                <AutoCheck passed={false} tip="CRA Programs Do Not Allow C/O" />
              ) : (
                <React.Fragment>
                  {checkList.loanPurpose.checked && (
                    <SignOff
                      user={checkList.loanPurpose.userName}
                      date={checkList.loanPurpose.timestamp}
                    />
                  )}
                </React.Fragment>
              )
            }
          />

          {/* Occupancy Type */}
          <ChecklistCard
            checked={checkList.occupancyType.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="occupancyType"
                  id="occupancyType"
                  checked={checkList.occupancyType.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="occupancyType">
                  Occupancy Type
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <Verify />
                  {fileData.occupancyType}
                </div>
                {fileData.occupancyType.includes('Primary') && (
                  <div>
                    <Verify tip="For Primary Residence" />
                    Commuting and Quality
                  </div>
                )}
                {fileData.occupancyType.includes('Second') && (
                  <div>
                    <Verify tip="For Second Home" />
                    LOE Requested
                  </div>
                )}
                {fileData.occupancyType.includes('Investment') && (
                  <div>
                    <Verify tip="For Investment Home" />
                    Reserves Requested
                  </div>
                )}
              </div>
            }
            alert={
              checkList.occupancyType.checked && (
                <SignOff
                  user={checkList.occupancyType.userName}
                  date={checkList.occupancyType.timestamp}
                />
              )
            }
          />

          {/* Property Type */}
          <ChecklistCard
            checked={checkList.propertyType.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="propertyType"
                  id="propertyType"
                  checked={checkList.propertyType.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="propertyType">
                  Property Type
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <Verify />
                  {fileData.propertyType}
                </div>
                {fileData.propertyType === 'PUD' && (
                  <div>
                    <Verify tip="For PUDs" />
                    Master Requested
                  </div>
                )}
                {fileData.propertyType === 'Condo' && (
                  <div>
                    <Verify tip="For Condos" />
                    HOA Questionnaires and Master Requested
                  </div>
                )}
                {fileData.propertyType.includes('Unit') && (
                  <div>
                    <Verify tip="For Multi-Unit Properties" />
                    Reserve Requested
                  </div>
                )}
              </div>
            }
            alert={
              checkList.propertyType.checked && (
                <SignOff
                  user={checkList.propertyType.userName}
                  date={checkList.propertyType.timestamp}
                />
              )
            }
          />

        {/* Housing History */}
        {fileData.programCode.includes('MTGP2') && (
          <ChecklistCard
            checked={checkList.housingHistory.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="housingHistory"
                  name="housingHistory"
                  disabled={!this.state.missedPayments}
                  checked={checkList.housingHistory.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="housingHistory">
                  Housing History
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <Verify tip={'For MTGP2 Programs (1 x 30 x 12)'} /> Any Late Payments Over Past 12 Months
                </div>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="missedPayments1"
                      name="missedPayments"
                      checked={this.state.missedPayments === '0'}
                      disabled={checkList.housingHistory.checked}
                      onChange={() => void 0}
                      onClick={() => {
                        this.setState({ missedPayments: '0' });
                        this.updateDynamicDataField([
                          ['MissedPayments', '', '0', '', ''],
                        ]);
                      }}
                    />
                    <label htmlFor="missedPayments1" className="form-check-label">
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="missedPayments2"
                      name="missedPayments"
                      checked={this.state.missedPayments === '1'}
                      disabled={checkList.housingHistory.checked}
                      onChange={() => void 0}
                      onClick={() => {
                        this.setState({ missedPayments: '1' });
                        this.updateDynamicDataField([
                          ['MissedPayments', '', '1', '', ''],
                        ]);
                      }}
                    />
                    <label htmlFor="missedPayments2" className="form-check-label">
                      No
                    </label>
                  </div>
                </div>
                <div>
                  
                </div>
              </div>
            }
            alert={
              checkList.housingHistory.checked && (
                <SignOff
                  user={checkList.housingHistory.userName}
                  date={checkList.housingHistory.timestamp}
                />
              )
            }
          />
          )}

          {/* Dwelling + ADUs > 4 */}
          {fileData.programCode.includes('MTGP1') && (
          <ChecklistCard
            checked={checkList.unitOver4.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="unitOver4"
                  name="unitOver4"
                  disabled={!this.state.moreThan4Units}
                  checked={checkList.unitOver4.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="unitOver4">
                  Multi-Unit
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <Verify tip={'For MTGP1 Programs'} /> Dwelling + ADUs {'>'} 4
                </div>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="moreThan4Units1"
                      name="moreThan4Units"
                      checked={this.state.moreThan4Units === '0'}
                      disabled={checkList.unitOver4.checked}
                      onChange={() => void 0}
                      onClick={() => {
                        this.setState({ moreThan4Units: '0' });
                        this.updateDynamicDataField([
                          ['MoreThan4Units', '', '0', '', ''],
                        ]);
                      }}
                    />
                    <label htmlFor="moreThan4Units1" className="form-check-label">
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="moreThan4Units2"
                      name="moreThan4Units"
                      checked={this.state.moreThan4Units === '1'}
                      disabled={checkList.unitOver4.checked}
                      onChange={() => void 0}
                      onClick={() => {
                        this.setState({ moreThan4Units: '1' });
                        this.updateDynamicDataField([
                          ['MoreThan4Units', '', '1', '', ''],
                        ]);
                      }}
                    />
                    <label htmlFor="moreThan4Units2" className="form-check-label">
                      No
                    </label>
                  </div>
                </div>
              </div>
            }
            alert={
              checkList.unitOver4.checked && (
                <SignOff
                  user={checkList.unitOver4.userName}
                  date={checkList.unitOver4.timestamp}
                />
              )
            }
          />
          )}

          {/* Income Type */}
          {(fileData.programCode.includes('MTGP2') && this.state.selfEmployed) && (
          <ChecklistCard
            checked={checkList.incomeType.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="incomeType"
                  name="incomeType"
                  disabled={!this.state.incomeVerify}
                  checked={checkList.incomeType.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="incomeType">
                  Income Type
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <Task
                    tip={
                      <span>
                        For MTGP2 Programs, If Self-Employed
                        <br />
                        We Need to Store Information For 
                        <br />
                        How The Income Will Be Verified
                      </span>
                    }
                  />
                  Income Verification Method
                </div>
                {(this.state.rateLocked && !LoginState.userRole.toLowerCase().includes('lock')) && (
                  <div style={{color:'red', fontSize:'12px'}}>(Please Contact Lock If You Need To Change This Value)</div>
                )}
                <div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="incomeVerify1"
                      name="incomeVerify"
                      checked={this.state.incomeVerify === '0'}
                      disabled={checkList.incomeType.checked || (this.state.rateLocked && !LoginState.userRole.toLowerCase().includes('lock'))}
                      onChange={() => void 0}
                      onClick={() => {
                        this.setState({ incomeVerify: '0' });
                        this.updateDynamicDataField([
                          ['IncomeVerify', '', '0', '', ''],
                        ]);
                        this.getUnderwritingData();
                      }}
                    />
                    <label htmlFor="incomeVerify1" className="form-check-label">
                      P&L Statement
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="incomeVerify2"
                      name="incomeVerify"
                      checked={this.state.incomeVerify === '1'}
                      disabled={checkList.incomeType.checked || (this.state.rateLocked && !LoginState.userRole.toLowerCase().includes('lock'))}
                      onChange={() => void 0}
                      onClick={() => {
                        this.setState({ incomeVerify: '1' });
                        this.updateDynamicDataField([
                          ['IncomeVerify', '', '1', '', ''],
                        ]);
                        this.getUnderwritingData();
                      }}
                    />
                    <label htmlFor="incomeVerify2" className="form-check-label">
                      Bank Statements
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="incomeVerify3"
                      name="incomeVerify"
                      checked={this.state.incomeVerify === '2'}
                      disabled={checkList.incomeType.checked || (this.state.rateLocked && !LoginState.userRole.toLowerCase().includes('lock'))}
                      onChange={() => void 0}
                      onClick={() => {
                        this.setState({ incomeVerify: '2' });
                        this.updateDynamicDataField([
                          ['IncomeVerify', '', '2', '', ''],
                        ]);
                        this.getUnderwritingData();
                      }}
                    />
                    <label htmlFor="incomeVerify3" className="form-check-label">
                      1099 Form
                    </label>
                  </div>
                </div>
              </div>
            }
            alert={
              checkList.incomeType.checked && (
                <SignOff
                  user={checkList.incomeType.userName}
                  date={checkList.incomeType.timestamp}
                />
              )
            }
          />
          )}

          {/* LTV */}
          <ChecklistCard
            checked={
              parseFloat(ltvFico.maxLtv) >=
              parseFloat(fileData.ltvCltvHcltv.split('%')[0])
            }
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="ltv"
                  id="ltv"
                  checked={
                    parseFloat(ltvFico.maxLtv) >=
                    parseFloat(fileData.ltvCltvHcltv.split('%')[0])
                  } /* This checks ltv and not hcltv. change later */
                  onChange={() => void 0}
                />
                <label className="form-check-label" htmlFor="ltv">
                  LTV/CLTV/HCLTV
                </label>
              </div>
            }
            body={
              <div>
                {ltvFico.maxLtv === 0 ? (
                  <span>Scenario Not Supported <small>(Check Matrix)</small></span>
                ) : (
                  <span>
                    {fileData.ltvCltvHcltv}{' '}
                    {` <= Max LTV (` + ltvFico.maxLtv + `%)`}
                  </span>
                )}
              </div>
            }
            alert={
              <AutoCheck
                passed={
                  parseFloat(ltvFico.maxLtv) >=
                  parseFloat(fileData.ltvCltvHcltv.split('%')[0])
                }
              />
            }
          />

          {/* DTI */}
          {(checkList.dti.checked = this.dtiUnderLimits())}
          <ChecklistCard
            checked={checkList.dti.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="dti"
                  id="dti"
                  checked={checkList.dti.checked}
                  onChange={() => void 0}
                />
                <label className="form-check-label" htmlFor="dti">
                  DTI
                </label>
              </div>
            }
            body={
              <div>
                {fileData.dti}{' '}
                {' <= Max (' +
                  ltvFico.maxFrontDTI +
                  '.00% / ' +
                  ltvFico.maxBackDTI +
                  '.00%)'}
              </div>
            }
            alert={<AutoCheck passed={checkList.dti.checked} />}
          />

          {/* PNL Preparer */}
          {(fileData.programCode.includes('MTGP1') && this.state.selfEmployed) && (
          <ChecklistCard
            checked={checkList.pnlPrep.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="pnlPrep"
                  name="pnlPrep"
                  disabled={!this.state.pnlPreparer}
                  checked={checkList.pnlPrep.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="pnlPrep">
                  P&L Preparer
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <Task
                    tip={
                      <span>
                        For MTGP1 Programs, If Self-Employed
                        <br />
                        We Need to Store Information For 
                        <br />
                        Who Prepared Their P&L Form
                      </span>
                    }
                  />
                  Who Prepared The Form
                </div>
                <div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="pnlPreparer1"
                      name="pnlPreparer"
                      checked={this.state.pnlPreparer === '0'}
                      disabled={checkList.pnlPrep.checked}
                      onChange={() => void 0}
                      onClick={() => {
                        this.setState({ pnlPreparer: '0' });
                        this.updateDynamicDataField([
                          ['PnlPreparer', '', '0', '', ''],
                        ]);
                      }}
                    />
                    <label htmlFor="pnlPreparer1" className="form-check-label">
                      CPA Prepared
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="pnlPreparer2"
                      name="pnlPreparer"
                      checked={this.state.pnlPreparer === '1'}
                      disabled={checkList.pnlPrep.checked}
                      onChange={() => void 0}
                      onClick={() => {
                        this.setState({ pnlPreparer: '1' });
                        this.updateDynamicDataField([
                          ['PnlPreparer', '', '1', '', ''],
                        ]);
                      }}
                    />
                    <label htmlFor="pnlPreparer2" className="form-check-label">
                      Borrower Prepared
                    </label>
                  </div>
                </div>
                {/* if rate locked (show warning message) */}
                {this.state.fileData.lockStartDate !== "---" && (
                  <div style={{fontSize:'13px', color:'red'}}>
                    (Rate Locked! Please Create a CIC Before Updating Value)
                  </div>
                )}
              </div>
            }
            alert={
              checkList.pnlPrep.checked && (
                <SignOff
                  user={checkList.pnlPrep.userName}
                  date={checkList.pnlPrep.timestamp}
                />
              )
            }
          />
          )}

          {/* Credit Report */}
          <ChecklistCard
            checked={checkList.creditReport.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="creditReport"
                  name="creditReport"
                  checked={checkList.creditReport.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="creditReport">
                  Credit Report
                </label>
              </div>
            }
            body={
              <div>
                <Task
                  tip={
                    <span>
                      If Broker CR is Provided, Re-Issue Through Byte Using Ref.
                      #<br />
                      Otherwise, Use Xactus (formerly Credit Plus) to Pull
                    </span>
                  }
                />
                Re-issue or Pull
              </div>
            }
            alert={
              checkList.creditReport.checked && (
                <SignOff
                  user={checkList.creditReport.userName}
                  date={checkList.creditReport.timestamp}
                />
              )
            }
          />

          {/* CREDIT SCORE - FICO */}
          {
            (checkList.fico.checked =
              parseFloat(ltvFico.minFico) <= parseFloat(fileData.creditScore))
          }
          <ChecklistCard
            checked={checkList.fico.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="fico"
                  id="fico"
                  checked={checkList.fico.checked}
                  onChange={() => void 0}
                />
                <label className="form-check-label" htmlFor="fico">
                  Credit Score
                </label>
              </div>
            }
            body={
              <div>
                {
                  (checkList.fico.checked =
                    parseFloat(ltvFico.minFico) <=
                    parseFloat(fileData.creditScore))
                }
                <p className="mb-0">
                  {fileData.creditScore === '---' ? (
                    <React.Fragment>No Credit Score Available</React.Fragment>
                  ) : (
                    <React.Fragment>
                      {ltvFico.minFico === 1000 ? (
                        <span>Scenario Not Supported <small>(Check Matrix)</small></span>
                      ) : (
                        <span>
                          {fileData.creditScore}{' '}
                          {` >= Min FICO (` + ltvFico.minFico + `)`}
                        </span>
                      )}
                    </React.Fragment>
                  )}
                </p>
              </div>
            }
            alert={
              <AutoCheck
                passed={
                  parseFloat(ltvFico.minFico) <=
                  parseFloat(fileData.creditScore)
                }
                tip={
                  fileData.creditScore === '---'
                    ? 'No Credit Score Found'
                    : 'Credit Score Under Minimum Required Limit'
                }
              />
            }
          />

          {/* If No Credit Reports Found = Fail */}
          {this.state.creditReports.length === 0 && (
            <ChecklistCard
              label={
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={false}
                    disabled={true}
                    id="noCreditReport"
                  />
                  <label className="form-check-label" htmlFor="noCreditReport">
                    Credit Report
                  </label>
                </div>
              }
              body={
                <div>
                  <Task tip="A Credit Report Is Required to Proceed" />
                  No Credit Report Found
                </div>
              }
              alert={<AutoCheck passed={false} />}
            />
          )}

          {/* Credit Report Issue Date */}
          <div>
            {this.state.creditReports.map(creditReport => {
              const changeExists =
                this.state['originalCrDate' + creditReport.index] !==
                this.state['crDate' + creditReport.index];
              const dateCheck = this.checkAgainstClosingDate(
                'Credit',
                this.state['crDate' + creditReport.index]
              );
              const expDate = this.getExpirationDate(
                'Credit',
                this.state['crDate' + creditReport.index]
              );
              if (changeExists) {
                checkList['cr' + creditReport.docID].checked = false;
                this.setChecklistField('cr' + creditReport.docID, false);
              }
              return (
                <React.Fragment key={creditReport.docID}>
                  <ChecklistCard
                    checked={checkList['cr' + creditReport.docID].checked}
                    label={
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name={'cr' + creditReport.docID}
                          id={'cr' + creditReport.docID}
                          checked={checkList['cr' + creditReport.docID].checked}
                          onChange={this._toggleCheckbox}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={'cr' + creditReport.docID}
                        >
                          Credit Report
                        </label>
                        <br />
                        <table width="100%">
                          <tbody>
                            <tr>
                              <td style={{ textAlign: 'center' }}>
                                <i
                                  className="bi bi-download"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    this.downloadLoanFile(creditReport.docID)
                                  }
                                ></i>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    }
                    body={
                      <div className="vstack gap-3">
                        <div className="input-group">
                          <span className="input-group-text">Issue Date:</span>
                          <input
                            id={'crDate' + creditReport.index}
                            className="form-control"
                            type="date"
                            name={'crDate' + creditReport.index}
                            value={
                              this.state['crDate' + creditReport.index] || ''
                            }
                            onChange={this.captureInput}
                            disabled={
                              checkList['cr' + creditReport.docID].checked
                            }
                          />
                          <button
                            className="btn btn-primary"
                            disabled={
                              !changeExists ||
                              checkList['cr' + creditReport.docID].checked
                            }
                            onClick={() =>
                              this.updateDynamicDataField([
                                [
                                  'CR Date',
                                  creditReport.docID,
                                  this.state['crDate' + creditReport.index],
                                  'cr' + creditReport.docID,
                                  'crDate' + creditReport.index,
                                ],
                              ])
                            }
                          >
                            {this.state[
                              'crDate' + creditReport.index + 'Updated'
                            ]
                              ? '✓'
                              : 'Update'}
                          </button>
                        </div>
                        {expDate && (
                          <div
                            className={`alert alert-${
                              expDate.length > 9 ? 'danger' : 'warning'
                            } fw-bold`}
                          >
                            Exp Date: {expDate}
                          </div>
                        )}
                        <div>
                          <TableZero>
                            <tbody>
                              <tr>
                                <td>
                                  <Verify tip="Check for Bankruptcy, Foreclosure, Short Sale, etc" />
                                </td>
                                <td>
                                  Public Records (BK, FC, SS, etc) <br />
                                  If Exists - Verify Seasoning Met
                                </td>
                              </tr>
                            </tbody>
                          </TableZero>
                        </div>
                      </div>
                    }
                    alert={
                      checkList['cr' + creditReport.docID].checked && (
                        <SignOff
                          user={checkList['cr' + creditReport.docID].userName}
                          date={checkList['cr' + creditReport.docID].timestamp}
                        />
                      )
                    }
                  />
                </React.Fragment>
              );
            })}
          </div>

          {/* Original Creditor Refinance */}
          {!fileData.loanPurpose.includes('Purchase') && (
            <ChecklistCard
              checked={checkList.originalCreditor.checked}
              label={
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    aria-label="Checkbox for following text input"
                    id="originalCreditor"
                    name="originalCreditor"
                    checked={checkList.originalCreditor.checked}
                    onChange={this._toggleCheckbox}
                  />
                  <label className="form-check-label" htmlFor="originalCreditor">
                    Original Creditor Refinance
                  </label>
                </div>
              }
              body={
                <div className="vstack gap-3">
                  <div>
                    <Verify tip={'For Refinance Loans, Check If Previous\nOriginator Was Also MTG'} /> Refinance With Original Creditor
                  </div>
                  <div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="creditorMtg1"
                        name="creditorMtg1"
                        checked={this.state.ocMtg === '1'}
                        disabled={checkList.originalCreditor.checked}
                        onChange={() => void 0}
                        onClick={() => {
                          this.setState({ ocMtg: '1' });
                          this.updateDynamicDataField([
                            ['OriginalCreditorMtg', '', '1', '', ''],
                          ]);
                        }}
                      />
                      <label htmlFor="creditorMtg1" className="form-check-label">
                        Yes
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="creditorMtg2"
                        name="creditorMtg2"
                        checked={this.state.ocMtg === '0'}
                        disabled={checkList.originalCreditor.checked}
                        onChange={() => void 0}
                        onClick={() => {
                          this.setState({ ocMtg: '0' });
                          this.updateDynamicDataField([
                            ['OriginalCreditorMtg', '', '0', '', ''],
                          ]);
                        }}
                      />
                      <label htmlFor="creditorMtg2" className="form-check-label">
                        No
                      </label>
                    </div>
                  </div>
                  <div>
                    
                  </div>
                </div>
              }
              alert={
                checkList.originalCreditor.checked && (
                  <SignOff
                    user={checkList.originalCreditor.userName}
                    date={checkList.originalCreditor.timestamp}
                  />
                )
              }
            />
          )}

          {/* MERS Property Search */}
          <ChecklistCard
            checked={checkList.mers.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="mers"
                  name="mers"
                  disabled={
                    !this.state.mersActiveProperties ||
                    this.state.mersActiveProperties.startsWith('(Please Wait')
                  }
                  checked={checkList.mers.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="mers">
                  MERS Search
                </label>
              </div>
            }
            body={
              <div>
                <Verify />
                {this.state.mersActiveProperties}
              </div>
            }
            alert={
              checkList.mers.checked && (
                <SignOff
                  user={checkList.mers.userName}
                  date={checkList.mers.timestamp}
                />
              )
            }
          />

          {/* Flood Insurance */}
          <div>
            {this.state.fldInsStart &&
              this.checkAgainstFundingDate(this.state.fldInsStart) &&
              checkList.flood.checked &&
              (checkList.flood.checked = false)}
            <ChecklistCard
              checked={checkList.flood.checked}
              label={
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="flood"
                    id="flood"
                    checked={checkList.flood.checked}
                    onChange={this._toggleCheckbox}
                  />
                  <label className="form-check-label" htmlFor="flood">
                    Flood Zone
                  </label>
                </div>
              }
              body={
                <div className="vstack gap-3">
                  <div className="d-flex">
                    <Verify tip="QC Will Check and Set Value" />
                    Property Is In Flood Zone: &nbsp;
                    <span className="fw-normal fw-bold">
                      {this.state.floodInsRequired ? 'Yes' : 'No'}
                    </span>
                  </div>
                  {this.state.floodInsRequired && (
                    <React.Fragment>
                      <div>
                        <Verify tip="If Yes Then Request" />
                        Request Flood Insurance
                      </div>
                    </React.Fragment>
                  )}
                </div>
              }
              alert={
                <React.Fragment>
                  {this.checkAgainstFundingDate(this.state.fldInsStart) && (
                    <AutoCheck
                      passed={false}
                      tip={this.checkAgainstFundingDate(this.state.fldInsStart)}
                    />
                  )}
                  {checkList.flood.checked && (
                    <SignOff
                      user={checkList.flood.userName}
                      date={checkList.flood.timestamp}
                    />
                  )}
                </React.Fragment>
              }
            />
          </div>

          {/* Change Status (Approved) */}
          <ChecklistCard
            checked={this.state.fileData.approvedDate !== '---'}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="changeApproved"
                  name="changeApproved"
                  disabled={true}
                  checked={this.state.fileData.approvedDate !== '---'}
                />
                <label className="form-check-label" htmlFor="changeApproved">
                  Approved
                </label>
              </div>
            }
            body={
              <div>
                <Verify tip="Change Loan Status to 'Approved'" />
                {this.state.fileData.approvedDate !== '---' ? (
                  <React.Fragment>
                    <span
                      className={`fw-normal badge border rounded-pill`}
                      style={{
                        marginRight: '8px',
                        color: 'black',
                        backgroundColor: STATUS_COLORS['approved'],
                      }}
                    >
                      Approved
                    </span>
                    {this.state.fileData.approvedDate}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <span
                      className={`fw-normal badge border rounded-pill`}
                      style={{
                        marginRight: '8px',
                        color: [
                          'clear to close',
                          'clear to fund',
                          'in post-funding',
                          'on hold',
                        ].includes(this.state.fileData.loanStatus.toLowerCase())
                          ? 'white'
                          : 'black',
                        backgroundColor:
                          STATUS_COLORS[
                            this.state.fileData.loanStatus.toLowerCase()
                          ],
                      }}
                    >
                      {this.state.fileData.loanStatus}
                    </span>
                    <button
                      className="btn btn-sm btn-outline-primary ms-2"
                      style={{ width: '65px' }}
                      disabled={
                        this.state.changingState ||
                        this.state.fileData.approvedDate !== '---'
                      }
                      onClick={() => this.setLoanStatus('Approved')}
                    >
                      {this.state.changingState === 'Approved'
                        ? '...'
                        : 'Change'}
                    </button>
                  </React.Fragment>
                )}
              </div>
            }
            alert={
              <AutoCheck
                passed={this.state.fileData.approvedDate !== '---'}
                tip="Approved Date Not Found"
              />
            }
          />

          {/* ConditionalApproval */}
          <ChecklistCard
            checked={checkList.conditionalApproval.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="conditionalApproval"
                  name="conditionalApproval"
                  checked={checkList.conditionalApproval.checked}
                  onChange={this._toggleCheckbox}
                />
                <label
                  className="form-check-label"
                  htmlFor="conditionalApproval"
                >
                  Send Email
                </label>
              </div>
            }
            body={
              <div>
                <div>
                  <Email />
                  Conditional Approval
                  <button
                    className="btn btn-sm btn-outline-primary ms-2"
                    onClick={() => {
                      this.setState({
                        showEmailTemplate: true,
                        emailTemplate: this.state.conditionalTemplate,
                        emailLog: this.state.conditionalEmailLog,
                      });
                    }}
                  >
                    Preview
                  </button>
                  {this.state.conditionalEmailLog.length > 0 && (
                    <button
                      className="btn"
                      type="button"
                      style={{
                        height: '24px',
                        maxWidth: '24px',
                        marginLeft: '10px',
                        pointerEvents: 'auto',
                      }}
                      onClick={() =>
                        this.setState({
                          showEmailLog: true,
                          emailLog: this.state.conditionalEmailLog,
                        })
                      }
                    >
                      <i
                        className="bi bi-envelope-check"
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          position: 'relative',
                          top: '-10px',
                          left: '-10px',
                        }}
                      />
                    </button>
                  )}
                </div>
              </div>
            }
            alert={
              checkList.conditionalApproval.checked && (
                <SignOff
                  user={checkList.conditionalApproval.userName}
                  date={checkList.conditionalApproval.timestamp}
                />
              )
            }
          />

          {this.state.checkList.complete.checked && this.allChecked() ? (
            <div className="text-center mt-4">
              <button
                className="btn btn-primary text-white"
                style={{ backgroundColor: '#84c488', borderColor: 'gray' }}
                disabled={true}
              >
                Completed ✓
              </button>
            </div>
          ) : (
            <div className="text-center mt-4">
              <button
                className="btn btn-primary text-white"
                onClick={() => this.markComplete()}
                disabled={
                  !this.allChecked() || this.state.checkList.complete.checked
                }
              >
                {this.allChecked() ? 'Mark Complete' : 'Not Completed'}
              </button>
            </div>
          )}

          {this.state.errorMessage && (
            <Toast
              type="danger"
              title={this.state.errorMessage}
              onClose={() => this.setState({ errorMessage: '' })}
            />
          )}
          {this.state.successMessage && (
            <Toast
              type="success"
              title={this.state.successMessage}
              onClose={() => this.setState({ successMessage: '' })}
            />
          )}

          <ModalEmail
            show={this.state.showEmailTemplate}
            template={this.state.emailTemplate}
            fileID={this.state.activeFile}
            emailLog={this.state.emailLog}
            onDecline={() =>
              this.setState({ showEmailTemplate: false, emailTemplate: [] })
            }
            onAccept={() =>
              this.setState({ showEmailTemplate: false, emailTemplate: [] })
            }
          />
          <ModalEmailLog
            show={this.state.showEmailLog}
            emailLog={this.state.emailLog}
            onDecline={() =>
              this.setState({ showEmailLog: false, emailLog: [] })
            }
          />
          <ModalConfirm
            show={this.state.showConfirmModal || this.state.showClearModal}
            onAccept={() => this.setCheckbox(this.state.showConfirmModal)}
            onDecline={() =>
              this.setState({ showConfirmModal: false, showClearModal: false })
            }
          >
            <div style={{ fontSize: '16px' }}>
              <b>{this.state.showConfirmModal ? 'Set' : 'Clear'} Checkbox:</b>
              <span style={{ marginLeft: '10px' }}>
                "{FindLabelFromName(this.state.confirmTitle)}"
              </span>
            </div>
          </ModalConfirm>
          <ModalConfirm
            show={this.state.showClearAllModal}
            onAccept={() => this.ClearAll()}
            onDecline={() => this.setState({ showClearAllModal: false })}
          >
            <div style={{ fontSize: '16px', textAlign: 'center' }}>
              <b>Are You Sure You Want to Clear <u>ALL</u> <br/>
              Checkbox Fields For This Checklist?</b>
            </div>
          </ModalConfirm>
        </div>
      </React.Fragment>
    );
  }
}
